import React, { useState, useRef, useCallback, useEffect } from 'react';

import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import { debounce } from 'alpinejs/src/utils/debounce';
import { getCookie } from '../../../../../js/helpers';
import ExtendedTextareaField from '@src/stories/elements/ExtendedTextareaField/ExtendedTextareaField';
import ExtendedCheckbox from '@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox';

const countries = [
  { label: 'Australia', value: 'Australia' },
  { label: 'New Zealand', value: 'New Zealand' },
] as any;

export default function ExtendedCartAddressForm ({
  customer,
  address,
  existingAddress,
  settings,
  translations,
  errorMessage,
  onSubmit,
  onAddressChange
}) {
  const formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addressType, setAddressType] = useState('Residential');
  const [hasError, setHasError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [savedFormData, setSavedFormData] = useState({
    'address[first_name]': '',
    'address[last_name]': '',
    'address[company]': '',
    'address[country]': '',
    'address[phone]': '',
    'address[address1]': '',
    'address[address2]': '',
    'address[city]': '',
    'address[province]': '',
    'address[zip]': '',
    'delivery_instructions': '',
    'address[businessAddressType]': false,
    'address[residentialAddressType]': true
  });

  useEffect(() => {
    const _savedFormData = getCookie('checkoutSingleShippingAddress');
    if (_savedFormData) {
      const parsedFormData = JSON.parse(decodeURIComponent(_savedFormData));
      setSavedFormData(parsedFormData);
      onAddressChange(parsedFormData);
    }
  }, []);
  
  useEffect(() => {
    if (existingAddress) {
      const toFormObject = {
        'address[first_name]': existingAddress?.first_name,
        'address[last_name]': existingAddress?.last_name,
        'address[company]': existingAddress?.company,
        'address[country]': existingAddress?.country,
        'address[phone]': existingAddress?.phone,
        'address[address1]': existingAddress?.address1,
        'address[address2]': existingAddress?.address2,
        'address[city]': existingAddress?.city,
        'address[province]': existingAddress?.province,
        'address[zip]': existingAddress?.zip,
        'address[residentialAddressType]': existingAddress?.residentialAddressType,
        'address[businessAddressType]': existingAddress?.businessAddressType,
        'delivery_instructions': '',
      }

      setSavedFormData(toFormObject);
      handleAddressChange(true);
    }
  }, [existingAddress]);

  function handleAddressCreate (event) {
    event.preventDefault();
    setIsSubmitting(true);
    setHasError(false);

    const formData = new FormData(formRef.current) as any;
    const formParams = new URLSearchParams(formData).toString();

    fetch('/account/addresses', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formParams
    }).then(response => {
      if (response.ok) {
        return response.text();

      } else {
        return Promise.reject('Failed to submit the form');
      }
    }).then(() => {
      const formObject = Object.fromEntries(formData);

      setHasError(false);
      setIsSaved(true);
      onSubmit(formObject);

    }).catch((error) => {
      console.error('Error:', error);
      setHasError(true);

    }).finally(() => setIsSubmitting(false));
  }

  const handleAddressChange = useCallback(
    debounce((isAddressSaved = false) => {
      const formData = new FormData(formRef.current) as any;
      const formObject = Object.fromEntries(formData);
      const encodedFormDataJSON = encodeURIComponent(JSON.stringify(formObject));
      document.cookie = `checkoutSingleShippingAddress=${encodedFormDataJSON}; path=/; max-age=604800`;
      setIsSaved(isAddressSaved);
      onAddressChange(formObject);
    }, 500), []
  );

  function checkIfFieldHasError (field) {
    return errorMessage === translations.address_error && address[`address[${field}]`] == ''
  }

  return (
    <form
      ref={formRef}
      id="address_form_new"
      className='flex flex-col gap-6'
      acceptCharset="UTF-8"
      onChange={() => handleAddressChange()}
      onSubmit={(event) => handleAddressCreate(event)}
      x-data="addressvalidation"
    >
      <input type="hidden" name="form_type" defaultValue="customer_address" />
      <input type="hidden" name="utf8" defaultValue="✓" />

      <div className='lg:flex items-center gap-6'>
        <ExtendedTextField
          name='address[first_name]'
          value={savedFormData['address[first_name]']}
          label='First Name'
          placeholder={`${translations?.first_name}`}
          className='flex-1 max-lg:mb-6'
          required
        />

        <ExtendedTextField
          name='address[last_name]'
          value={savedFormData['address[last_name]']}
          label='Last Name'
          placeholder={`${translations?.last_name}`}
          className='flex-1'
          required
        />
      </div>

      <div className='lg:flex items-center gap-6'>
        <ExtendedTextField
          name='address[company]'
          value={savedFormData['address[company]']}
          label='Company'
          placeholder={`${translations?.company}`}
          className='flex-1 max-lg:mb-6'
        />

        <ExtendedSelectField
          name='address[country]'
          options={countries}
          value={savedFormData['address[country]']}
          label='Country'
          placeholder={`${translations?.country}`}
          xref='address_field'
          className='flex-1'
          required
          onChange={() => handleAddressChange()}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-6"> 
          <div className='w-full lg:w-1/2'> 
            <ExtendedTextField
              name='address[phone]'
              value={savedFormData['address[phone]']}
              label='Mobile'
              inputType="number"
              placeholder={`${translations?.mobile}`}
              required
              hasError={settings.is_phone_required && checkIfFieldHasError('phone')}
            />
          </div>

        <div className="flex flex-col w-full lg:w-1/2 h-full">
            <div className="form-field__label"> Address Type </div>
            <div className='flex gap-3 w-full h-[48px]'>
              <div className='flex gap-3 w-full items-center'>
                  <p>{'Residential'}</p>   
                  <ExtendedCheckbox name="address[residentialAddressType]" 
                    value={`${addressType == 'Residential'}`}
                    checked={addressType == 'Residential'}
                    onChange={(e) => { 
                      setAddressType('Residential');
                      handleAddressChange();
                    }} />
                </div> 
                <div className='flex gap-3 w-full items-center'>       
                  <p>{'Business'}</p>          
                  <ExtendedCheckbox name="address[businessAddressType]" 
                    value={`${addressType == 'Business'}`}
                    checked={addressType == 'Business'}
                    onChange={(e) => { 
                      setAddressType('Business');
                      handleAddressChange();
                    }} />
                </div>
            </div>
        </div>
      </div>
 


      <ExtendedTextField
        name='address[address1]'
        value={savedFormData['address[address1]']}
        label='Address'
        placeholder={`${translations?.address1}`}
        onChange={() => {handleAddressChange();}}
        onFocus={() => {handleAddressChange();}}
        onBlur={() => {handleAddressChange();}}
        xref='address_field'
        xinit="setupGooglePlacesAutocomplete"
        hasError={checkIfFieldHasError('address1')}
        required
      />

      <ExtendedTextField
        name='address[address2]'
        value={savedFormData['address[address2]']}
        placeholder={`${translations?.address2}`}
      />

    <div className='flex flex-col lg:flex-row gap-6'> 
      <ExtendedTextField
        name='address[city]'
        value={savedFormData['address[city]']}
        onChange={() => handleAddressChange()}
        label='Suburb'
        className="lg:w-1/3"
        xref='city'
        placeholder={`${translations?.city}`}
        required
        hasError={checkIfFieldHasError('city')}
      />

      <ExtendedTextField
        name='address[province]'
        value={savedFormData['address[province]']}
        label='State'
        onChange={() => handleAddressChange()}
        xref='province'
        className="lg:w-1/3"
        placeholder={`${translations?.state}`}
      />

      <ExtendedTextField
        name='address[zip]'
        value={savedFormData['address[zip]']}
        label='Post Code'
        onChange={() => handleAddressChange()}
        xref='zip'
        className="lg:w-1/3"
        placeholder={`${translations?.postcode}`}
        required
        hasError={checkIfFieldHasError('zip')}
      />
    </div>

      <ExtendedTextareaField
        name='delivery_instructions'
        value={savedFormData['delivery_instructions']}
        label='Delivery Instructions'
        placeholder={`${translations?.delivery_instructions}`}
        disableEmoji
      />

      {customer &&
        <>
          {isSaved
            ? <p className='text-mb-green text-sm'>
                Address is saved to address book.
              </p>

            : <ExtendedButton
                type='submit'
                disabled={isSubmitting}
                icon={isSubmitting ? 'loader-alt animate-spin text-xl' : ''}
                label={isSubmitting ? '' : 'Save to Address book'}
                style='primary'
                className='lg:max-w-[200px]'
              />
          }

          {hasError &&
            <p className='text-mb-red text-sm'>
              Form submission failed. Please try again.
            </p>
          }
        </>
      }
    </form>
  )
}
