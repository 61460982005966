import React, { useState, useCallback } from 'react';
import clsx from 'clsx';

import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import ExtendedCheckbox from '@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox';
import ExtendedTextareaField from '@src/stories/elements/ExtendedTextareaField/ExtendedTextareaField';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedCustomSelectField from './ExtendedCustomSelectField';
import { updateItems } from "frontend/js/useShopifyCart";
import { debounce } from 'alpinejs/src/utils/debounce';

const BUNDLE_TYPE = 'Bundle Container';

export default function ExtendedCartItemDetails ({
  item,
  itemIndex,
  hasCards,
  cardTypes,
  giftWrapTypes,
  optionsInCart,
  updatingCart,
  showGiftWrap,
  hasOtherItems,
  translations,
  onItemDelete,
  onApplyMessageToAll,
  lineItem,
  isHamper,
  characterLimit
}) {

  const [isLoading, setIsLoading] = useState(false);
  const [hasCard, setHasCard] = useState(!!item.properties._card_type);
  const [emptyMessage, setEmptyMessage] = useState(item.properties._is_card_message_blank);

  async function handleQuantityUpdate(item, itemIndex) {
    if (isLoading) return;

    setIsLoading(true);
    const updates = {}

    updates[`${item.key}`] = 0;

    if (item.properties._card_type) {
      const optionInCart = optionsInCart[item.properties._card_type];
      if (optionInCart) {
        optionInCart.quantity -= 1

        if (optionInCart.key) {
          updates[`${optionInCart.key}`] = optionInCart.quantity;
        }
      }
    }

    if (item.properties._gift_wrap_type) {
      const optionInCart = optionsInCart[item.properties._gift_wrap_type];

      if (optionInCart) {
        optionInCart.quantity -= 1

        if (optionInCart.key) {
          updates[`${optionInCart.key}`] = optionInCart.quantity;
        }
      }
    }

    await updateItems({ updates });

    onItemDelete(item.variant_id, itemIndex);
    setIsLoading(false);
  }

  const handleChange = useCallback(
    debounce((event, item) => {
      const value = event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

      item.properties[event.target.name] = value;
    }, 500), []
  );

  function handleCardTypeChange (value, item, name) {
    handleSelectChange(value, item, name);
    if(value == 0) {
      setHasCard(false);
    }
    else {
      setHasCard(!!value);
    }
  }

  function handleSelectChange (value, item, name) {
    // Wipe Card Message on Cart Type reset
    if(name == '_card_type' && value == 0) {
      item.properties['_card_message'] = '';
    }
    if (item.properties[name] !== value) {
      if (value) {
        if (optionsInCart[value]) {
          optionsInCart[value].quantity += 1;
          optionsInCart[value].isUpdated = true;

        } else {
          optionsInCart[value] = {
            id: value,
            quantity: 1,
            isNew: true,
          }
        }

      } else if (optionsInCart[item.properties[name]]) {
        optionsInCart[item.properties[name]].quantity -= 1;
        optionsInCart[item.properties[name]].isUpdated = true;
      }

      item.properties[name] = value;
    }


  }

  const handleCheck = (event) => {
    console.log(event.target.checked);
    setEmptyMessage(event.target.checked);
  }

  return (
    <div className='flex flex-col gap-2'>
      <hr className={clsx('bg-mb-neutral-500 my-2',{
        'max-lg:hidden': itemIndex === 0
      })} />
      
      <div className='flex items-center justify-between gap-4'>
        <div className='font-medium'>
          {translations.item} {itemIndex + 1}
        </div>

        <span
          className={clsx('text-2xl cursor-pointer', {
            'icon-trash': !isLoading,
            'icon-loader-alt animate-spin': isLoading
          })}
          onClick={() => handleQuantityUpdate(item, itemIndex)}
        ></span>
      </div>

      <form
        className='flex flex-col gap-4'
        onSubmit={(event) => event.preventDefault()}
        onChange={(event) => handleChange(event, item)}
      > 
        { isHamper && 
          <ExtendedTextField
            name='_recipient_name'
            label='Recipient name'
            placeholder='Recipient name'
            required
            key={`recipient_name_${item.properties._recipient_name}`}
            value={item.properties._recipient_name}
            hasError={item.formErrors?.recipient_name}
            errorMessage={item.formErrors?.recipient_name}
          />
        }
        {hasCards &&
          <>
            <div className='flex max-lg:flex-col gap-4 lg:gap-6'>
             { isHamper && <ExtendedCustomSelectField
                name='_card_type'
                placeholder='Select a card (optional)'
                label={`Card ${itemIndex + 1}`}
                options={cardTypes}
                disabled={isLoading || updatingCart}
                key={`card_type_${item.properties._card_type}`}
                value={item.properties._card_type}
                hasError={item.formErrors?.card_type}
                errorMessage={item.formErrors?.card_type}
                className='flex-1'
                lineItem={lineItem}
                onChange={(value) => handleCardTypeChange(value, item, '_card_type')}
              />
             }
              {showGiftWrap && isHamper &&
                <ExtendedCustomSelectField
                  name='_gift_wrap_type'
                  label={`Gift wrapping ${itemIndex + 1}`}
                  options={giftWrapTypes}
                  lineItem={lineItem}
                  placeholder='Select a gift wrap (optional)'
                  disabled={isLoading || updatingCart}
                  key={`gift_wrap_type_${item.properties._gift_wrap_type}`}
                  value={item.properties._gift_wrap_type}
                  hasError={item.formErrors?.gift_wrap_type}
                  errorMessage={item.formErrors?.gift_wrap_type}
                  className='flex-1'
                  onChange={(value) => handleSelectChange(value, item, '_gift_wrap_type')}
                />
              }
            </div>
            
            {hasCard &&
              <div>
                <div className='flex items-center justify-between gap-4 mb-2'>
                  <div className='text-sm font-medium'>
                    Type your message...
                  </div>

                  <ExtendedCheckbox
                    className='item-details__blank-checkbox desktop'
                    key={`is_card_message_blank_${item.properties._is_card_message_blank}`}
                    checked={emptyMessage}
                    onChange={(event)=> { handleCheck(event) }}
                    disabled={isLoading || updatingCart}
                    name='_is_card_message_blank'
                    label='I would like it to be left blank'
                  />
                </div>
                <div className="extended-cart_message-field"> 
                  <ExtendedTextareaField
                    name='_card_message'
                    key={`card_message_${item.properties._card_message}`}
                    disabled={ emptyMessage || isLoading || updatingCart}
                    value={emptyMessage ? '' : item.properties._card_message }
                    required={!item.properties._is_card_message_blank}
                    maxCharacters={characterLimit}
                    hasError={item.formErrors?.card_message}
                    errorMessage={item.formErrors?.card_message}
                    disableEmoji
                  />
                </div>

                <ExtendedCheckbox
                  className='item-details__blank-checkbox mobile'
                  key={`is_card_message_blank_${item.properties._is_card_message_blank}`}
                  checked={emptyMessage}
                  onChange={(event)=> { handleCheck(event) }}
                  disabled={ emptyMessage || isLoading || updatingCart}
                  name='_is_card_message_blank'
                  label='I would like it to be left blank'
                />

                {hasOtherItems &&
                  <ExtendedButton
                    type='button'
                    label='Apply message to all'
                    style='secondary'
                    className='mt-4'
                    disabled={isLoading || updatingCart}
                    onClick={() => onApplyMessageToAll(item.properties._card_message)}
                  />
                }
              </div>
            }
          </>
        }
      </form>
    </div>
  )
}