import * as React from "react";
import {
    formatPrice,
    formatPriceWithoutTrailingZeros,
    interpolateTranslation,
    appendPrice,
  } from "frontend/js/helpers";

const ExtendedMegamenuFeaturedImage = ({ image, url, title, index, price, comparePrice, translations, currencySymbol  }) => {
      const currentPrice = formatPrice(price, '$');
      const oldPrice = formatPriceWithoutTrailingZeros(
        comparePrice,
        currencySymbol,
      );
      const oldPriceText = interpolateTranslation(
        translations,
        "old_price_html",
        "old_price",
        oldPrice,
      );
      const oldPriceTextSplit = oldPriceText.split(" ");
      const oldPricePricing = oldPriceTextSplit[oldPriceTextSplit.length - 1];
      const oldPriceLabel = oldPriceTextSplit.slice(0, -1).join(" ");

    return (
        <div key={`cta-${index}`} className="megamenu__featured-items-container relative h-full">
            <a href={url} className="flex flex-col gap-4">
                <div className="w-full h-auto ">
                    <img src={image} className="w-full object-cover max-h-[294px]" alt={title}/>
                </div>

                <div className="flex flex-row justify-between">
                    <div className="megamenu__featured-items-title">{title}</div>

                    <div className="flex items-center gap-2"> 
                      <span className="product-tile__details__price-value">
                          {currentPrice}
                      </span>
                      { comparePrice != 0 &&       
                      <span className="product-tile__details__price-old montserrat-regular flex gap-[2px]">
                        {oldPriceLabel}
                        
                        <span className="line-through">
                          {oldPricePricing}
                        </span>
                      </span> }
                
                    </div>

                </div>
            </a>
        </div>
    );
};

export default ExtendedMegamenuFeaturedImage;
