import { useState } from "react";
import api from '../../js/api';

const useCustomerAccessToken = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const getAccessToken = async (email, password) => {
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);

        const token = api.getStorefrontToken(); // Assuming your api object has a method to get the token
        const url = '/api/2024-07/graphql.json'; // Shopify Storefront GraphQL API endpoint

        const query = `
          mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
              customerAccessTokenCreate(input: $input) {
                customerAccessToken {
                  accessToken
                }
                customerUserErrors {
                  message
                }
              }
            }
        `;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': token, // Token from your api
                },
                body: JSON.stringify({
                    query,
                    variables: {
                        input: {
                            email: email,
                            password: password
                        }
                    },
                }),
            });

            const responseData = await response.json();
            if (responseData.data.customerAccessTokenCreate?.customerUserErrors) {
                let errorMessage = '';
                const errors = responseData.data.customerAccessTokenCreate?.customerUserErrors;

                if (errors.length > 0) {
                    for (const error of errors) {
                        errorMessage += `${error.message}\n`;
                    }

                    console.error('Failed to get access token.', responseData);
                    console.log('Error Message', errorMessage);
                    setErrorMessage(errorMessage);
                    throw new Error(errorMessage || 'Failed to get access token.');
                }
            }
            const accessTokenForCustomer = responseData.data.customerAccessTokenCreate.customerAccessToken.accessToken;
            localStorage.setItem('accessToken', accessTokenForCustomer);
            setSuccessMessage(`Access token created successfully.`)
            return { success: true, accessToken:  accessTokenForCustomer}
        } catch (error) {
            console.error('Error getting access token:', error);
            const errorMessage = error.message;
            switch(true){
                case errorMessage.includes('Unidentified'):
                    setErrorMessage( 'Account details incorrect. Please re-enter credentials.');
                break;
            }
        } finally {
            setLoading(false);
        }
    }

    const getCustomer = async (accessToken) => {
        setLoading(true);

        const token = api.getStorefrontToken(); // Assuming your api object has a method to get the token
        const url = '/api/2024-07/graphql.json'; // Shopify Storefront GraphQL API endpoint

        const query = `
          query {
            customer(customerAccessToken: "${accessToken}") {
              id
              business_name: metafield(key: "business_name", namespace: "custom") {
                key
                namespace
                value
              }
            }
          }
        `;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Shopify-Storefront-Access-Token': token, // Token from your api
                },
                body: JSON.stringify({ query }),
            });

            const responseData = await response.json();
            return responseData;

        } catch (error) {
            console.error('Error getting customer:', error);
        } finally {
            setLoading(false);
        }
    }

    return {
        email,
        setEmail,
        loading,
        errorMessage,
        successMessage,
        getAccessToken,
        getCustomer
    };
};

export default useCustomerAccessToken;
