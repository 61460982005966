import VideoContainer from "@mindarc/arctheme-components/frontend/src/stories/components/sections/VideoContainer/VideoContainer";
// import { Testimonials } from "@mindarc/arctheme-components/frontend/src/stories/components/sections/Testimonials/Testimonials";
import { Testimonials } from "../src/stories/sections/Testimonials/Testimonials";
import { CollectionFilter } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Filters/Filters";

import { FAQ } from "@mindarc/arctheme-components/frontend/src/stories/components/sections/FAQ/FAQ";

// Not in Core
import ExtendedMarquee from "../src/stories/sections/ExtendedMarquee/ExtendedMarquee";
import CategoryBanner from "../src/stories/components/CategoryBanner/CategoryBanner.tsx";
import CollectionHeader from "../src/stories/components/CollectionHeader/CollectionHeader.tsx";
import SearchResultsHeader from "../src/stories/components/SearchResultsHeader/SearchResultsHeader.tsx";
import NewsletterSignup from "@src/stories/components/NewsletterSignup/NewsletterSignup";
import ExtendedFAQ from "../src/stories/components/ExtendedFAQ/ExtendedFAQ";
import ExtendedFeaturedRecipe from "../src/stories/sections/ExtendedFeaturedRecipe/ExtendedFeaturedRecipe";
import ExtendedFeaturedBlog from "../src/stories/sections/ExtendedFeaturedBlog/ExtendedFeaturedBlog";
import ExtendedRecipeReviews from "@src/stories/sections/ExtendedRecipeReviews/ExtendedRecipeReviews";

// ALPINE JS
import newsletter from "../js/alpine/components/newsletter";
import ExtendedMulticolumn from "../src/stories/sections/ExtendedMulticolumn/ExtendedMulticolumn";
import ExtendedTextWithImage from "../src/stories/sections/TextWithImage/TextWithImage";
import ExtendedBanner from "../src/stories/sections/ExtendedBanner/Banner";

import ExtendedHeroBanner from "../src/stories/sections/ExtendedHeroBanner/ExtendedHeroBanner";
import ExtendedFeaturedCategories from "../src/stories/sections/ExtendedFeaturedCategories/ExtendedFeaturedCategories";
import ExtendedCollectionBanner from "../src/stories/sections/ExtendedCollectionBanner/ExtendedCollectionBanner";
import ExtendedPageHeader from "../src/stories/sections/ExtendedPageHeader/ExtendedPageHeader.tsx";
import ExtendedFeaturedCompanies from "../src/stories/sections/ExtendedFeaturedCompanies/ExtendedFeaturedCompanies";
import Timeline from "../src/stories/sections/Timeline/Timeline";
import CollectionList from "../src/stories/sections/CollectionList/CollectionList";
import ContactUsForm from "../src/stories/sections/ContactUsForm/ContactUsForm";

export function MulticolumnBlockSection({ ...props }) {
  return (
    <ExtendedMulticolumn
      settings={props.settings}
      headerText={props.settings.section?.title}
      subtext={props.settings.section?.subtext}
      tagline={props.settings.section?.tagline}
      columns={props.settings?.blocks}
      columns_count={props.settings.section?.columns_count}
      columns_count_mobile={props.settings.section?.columns_count_mobile}
      gap={props.settings.section?.gap}
    />
  );
}

export function VideoContainerSection({ ...props }) {
  return (
    <VideoContainer
      containerTitle={props.settings.section.title}
      settings={props.settings.section}
      videos={props.settings.blocks}
    />
  );
}

export function MarqueeSection({ ...props }) {
  return (
    <ExtendedMarquee
      marquee_blocks={props.settings.blocks}
      props={props.settings}
      marquee_speed={props.settings.section.marquee_speed}
      marquee_play_pause={props.settings.section.marquee_play_pause}
      block_gap={props.settings.section.block_gap}
      settings={props.settings.section}
    />
  );
}

export function SectionDeliveryImages({ ...props }) {
  const { settings } = props;
  const { section } = settings;
  const section_settings = {
    ...section,
    layout_type: "stacked-link-images",
    layout_type_mobile: "stacked-link-images",
  };

  return (
    <ExtendedTextWithImage
      reverselayout={false}
      settings={section_settings}
      shipping_rates={props.settings.shipping_rates}
      shipping_cost_id={props.settings.shipping_cost_id}
      customer={props.settings.customer}
      logged_in_threshold={props.settings.logged_in_threshold}
      logged_out_threhold={props.settings.logged_out_threhold}
      free_price_bypass={props.settings.free_price_bypass}
      blocks={props.settings.blocks}
    />
  );
}

export function SectionTextWithImage({ ...props }) {

  return (
    <ExtendedTextWithImage
      reverselayout={false}
      settings={props.settings.section}
  
      blocks={props.settings.blocks}
    />
  );
}

export function FAQComponent({ ...props }) {
  return <FAQ settings={props.settings} />;
}

export function SectionTestimonials({ ...props }) {
  return (
    <Testimonials
      title={props.settings.section.title}
      subtext={props.settings.section.subtext}
      reviews={props.settings.blocks}
    />
  );
}

export function SectionNewsletterSubscribe({ ...props }) {
  async function customerSubscribe(email) {
    // console.log('Subscribing Customer: ' + email);
    await newsletter.component().subscribe(email);
  }

  return (
    <NewsletterSignup settings={props.settings} onClick={customerSubscribe} />
  );
}

export function SectionBanner({ ...props }) {
  return (
    <ExtendedBanner
      banners={props.settings.blocks}
      useCarousel={props.settings.section.use_carousel}
      section_settings={props.settings.section}
    />
  );
}

export function SectionHeroBanner({ ...props }) {
  return <ExtendedHeroBanner settings={props.settings} />;
}

export function SectionFeaturedCategories({ ...props }) {
  return <ExtendedFeaturedCategories settings={props.settings} />;
}

export function SectionCollectionBanner({ ...props }) {
  return <ExtendedCollectionBanner settings={props.settings} />;
}

export function SectionCategoryBanner({ ...props }) {
  return <CategoryBanner settings={props.settings} />;
}

export function SectionCollectionHeader({ ...props }) {
  return <CollectionHeader settings={props.settings} />;
}

export function SectionSearchResultsHeader({ ...props }) {
  return <SearchResultsHeader settings={props.settings} />;
}

export function CollectionFilters({ ...props }) {
  return (
    <CollectionFilter
      title={props.settings.section.title}
      filters={props.settings.blocks}
    />
  );
}

export function SectionFeaturedCompanies({ ...props }) {
  return (
    <ExtendedFeaturedCompanies
      settings={props.settings}
      title={props.settings.section.title}
      logos={props.settings.blocks}
      use_greyscale={props.settings.section.use_greyscale}
    />
  );
}

export function SectionFAQ({ ...props }) {
  return (
    <ExtendedFAQ
      props={props.settings}
      settings={props.settings.section}
      blocks={props.settings.blocks}
    />
  );
}

export function SectionPageHeader({ ...props }) {
  return (
    <ExtendedPageHeader
      settings={props.settings}
      blocks={props.settings.blocks}
      customMetafield={{ "recipe_listing_banner_description": props.settings?.recipe_listing_banner_description, "recipe_listing_banner_image": props.settings?.recipe_listing_banner_image }}
    />
  );
}

export function SectionTimeline({ ...props }) {
  return <Timeline settings={props.settings} />;
}

export function SectionCollectionList({ ...props }) {
  return <CollectionList settings={props.settings} />;
}

export function ContactUsComponent({ ...props }) {
  return <ContactUsForm settings={props.settings} />;
}

export function SectionFeaturedRecipe({ ...props }) {
  const all_recipes = props.settings.all_recipes;
  const recipeHandle = props.settings.recipe;
  const matchingRecipe = all_recipes.find(
    (recipe) => recipeHandle == recipe.url,
  );
  return (
    <ExtendedFeaturedRecipe settings={props.settings} recipe={matchingRecipe} />
  );
}

export function SectionFeaturedBlog({ ...props }) {
  return <ExtendedFeaturedBlog settings={props.settings} />;
}

export function SectionRecipeReviews({ ...props }) {
  return <ExtendedRecipeReviews settings={props.settings} />;
}
