
import React, { useState, useEffect } from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";  
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

const ExtendedProductVariantSelect = ({ props }) => {
  const { product, translations } = props.settings;
  const { siblings, colour_siblings, active_swatch_url, show_color_swatches } = product;
  let options = []

  if (siblings?.sibling_products) {
    options = siblings.sibling_products
      .filter((prod) => prod.title != product.title)
      .map((product) => ({
        value: product.url,
        label: product.title
      }))
  }

  const handleChange = (option) => {
    window.location.href = option.value;
  }

  const renderSwatches = (swatches) => {
    return (
      <div className="flex flex-wrap items-center gap-2">
        {swatches.map((swatch, index) => {
          const isActive = swatch.handle === active_swatch_url;
          const swatchLabel = swatch.label?.split("|")[0];
          return (
            <a
              key={index}
              href={isActive ? undefined : swatch.url}
            >
              {(
                <span
                  className={`product-variants-select__color ${
                    isActive ? 'border border-mb-black' : 'border-transparent'
                  }`}
                  style={{ backgroundColor: swatch.colour }}
                  title={swatch.color}
                />
              )}
            </a>
          );
        })}
      </div>
    );
  };

  return (
    <div className={'product-variants-select flex flex-col gap-6'}>
      {options.length > 0 && (
        <>
          <div className={'product-variants-select__label'}> {translations?.variants} </div>
          <ExtendedSelectField
              options={options}
              placeholder={product.title}
              onChange={(option) => handleChange(option)}
          />
        </>
      )}

      {colour_siblings?.sibling_products && colour_siblings?.sibling_products.length > 0 && show_color_swatches && (
        <div className="gap-2 flex flex-col">
          <div className={'product-variants-select__label'}> {translations?.colour} </div>
          {renderSwatches(colour_siblings.sibling_products)}
        </div>
      )}

    </div>
  )
}


export default ExtendedProductVariantSelect;



