import React from "react";
import Image from "../../elements/ExtendedImage/ExtendedImage";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

const HeroBannerOverlay = ({ settings, block, goToSlide }) => {
  const { color_schema } = block;
  const block_settings = block.settings;
  const { section } = settings;

  return (
    <div
      className={`hero-banner__overlay top-0 h-full ${section.horizontal_content_position_desktop}`}
      style={{
        position: "absolute",
      }}
    >
      <div
        className={`hero-banner__content ${section.vertical_content_position_desktop} color-${color_schema}`}
        style={{
          backgroundImage: `url(${block_settings.text_content_background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="hero-banner__slider-buttons mb-auto">
          <ExtendedButton
            icon="chevron-left"
            style="dark-primary"
            className="hero-banner__slider-button prev"
            onClick={() => goToSlide("previous")}
          />

          <ExtendedButton
            icon="chevron-right"
            style="dark-primary"
            className="hero-banner__slider-button next"
            onClick={() => goToSlide("next")}
          />
        </div>

        <Headings text={block_settings.title} type={"h2"} />
        <Paragraph text={block_settings.subtext} type={"b2"} />

        {block_settings.show_contact_links && (
          <div className="hero-banner__external-links">
            <ExtendedButton
              style="dark-text"
              label="Call us"
              icon="phone-call"
              link={`tel:${block_settings.contact_number}`}
            />

            <ExtendedButton
              style="dark-text"
              label="Email"
              icon="mail-send"
              link={`mailto:${block_settings.contact_email}`}
            />

            <ExtendedButton
              style="dark-text"
              label="Live Chat"
              icon="face"
              link={block_settings.external_live_chat_url}
            />
          </div>
        )}

        <div className={`hero-banner__cta ${block_settings.cta_alignment}`}>
          {block_settings.primary_button_text && (
            <ExtendedButton
              style={block_settings.primary_button_style}
              label={block_settings.primary_button_text}
              link={block_settings.primary_button_url || "#"}
            />
          )}

          {block_settings.secondary_button_text && (
            <ExtendedButton
              style={block_settings.secondary_button_style}
              label={block_settings.secondary_button_text}
              link={block_settings.secondary_button_url || "#"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroBannerOverlay;
