import "liquid-ajax-cart";
import React from "react";
import ReactDOM from "react-dom/client";
import Alpine from "alpinejs";
import persist from "@alpinejs/persist";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineWire from "../js/alpine/plugins/wire";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";
import {
  Accordion,
  FindInStoreBlock,
  PDPMainComponent,
  ProductGalleryComponent,
  ProductStockStatus,
  ProductSwatchesComponent,
  ProductTileComponent,
  ProductPaymentsComponent,
  ProductAddToCartComponent,
  ProductVariantSelectComponent,
  ProductMonogramComponent
} from "../components/Product";

import {
  RecentlyViewedProductsList,
  RecommendedProductList,
  RecipeInclusionProductsList,
  RecipeRecommendationsList,
  RecipeSliderList,
  WishlistComponent,
} from "../components/List";
import {
  CollectionFilters,
  SectionCategoryBanner,
  SectionCollectionHeader,
  SectionSearchResultsHeader,
  MarqueeSection,
  MulticolumnBlockSection,
  SectionBanner,
  SectionHeroBanner,
  SectionCollectionBanner,
  SectionFeaturedCompanies,
  SectionFeaturedCategories,
  SectionNewsletterSubscribe,
  SectionTestimonials,
  SectionTextWithImage,
  SectionFAQ,
  SectionDeliveryImages,
  VideoContainerSection,
  SectionPageHeader,
  SectionTimeline,
  SectionCollectionList,
  ContactUsComponent,
  SectionFeaturedRecipe,
  SectionFeaturedBlog,
  SectionRecipeReviews,
} from "../components/Sections";

import {
  FooterComponent,
  HeaderComponent,
  MegamenuBlock,
  PromobarComponent,
} from "../components/Navigation";
import { NostoComponent } from "../components/Addons";
import { StoreLocatorPage } from "../components/Pages";

import { StyleguidePage } from "../components/Styleguide/Styleguide";

import {
  CollectionPageComponent,
  CollectionSidebarComponent,
  CollectionToolbarComponent,
  HeaderSearchComponent,
  SearchResultsPageComponent,
  MinicartComponent,
  CartComponent,
  ReviewsListComponent,
  TrackYourOrder, AccountLandingComponent,
} from "../components/Ecommerce";
import {
  SocialLinksComponent,
  TagListBlock,
  LogoComponent,
  TitleComponent,
  DescriptionComponent,
  ButtonComponent,
} from "../components/Blocks";
import { ProductsList, BlogsList } from "../components/List";
import {
  CompleteTheLookComponent,
  CustomerAddressesComponent,
  ExtendedMobileAccountMenuComponent,
  GiftFinderComponent,
  RecipeDetailPageComponent,
  RecipeListingPageComponent,
  SignupPopupComponent,
  FoodClubSignupComponent,
  GoogleRatingComponent
} from "../components/Client";
import {
  BlogArticleComponent,
  BlogArticleTileComponent,
} from "../components/Blogs";
import AccountPopupComponent from "../components/AccountPopupComponent";
import ExtendedCollection from "@src/stories/ecommerce/ExtendedCollection/ExtendedCollection";
import ExtendedShippingCalculator from "@src/stories/components/ExtendedShippingCalculator/ExtendedShippingCalculator";
import ExtendedProductInclusions from "@src/stories/components/ExtendedProductInclusions/ExtendedProductInclusions";
import ExtendedArticles from "@src/stories/components/ExtendedArticles/ExtendedArticles";
import ExtendedArticle from "@src/stories/components/ExtendedArticle/ExtendedArticle";

import("../js/facets.js");

hasBodyClass("product-template") && import("../js/prodify.js");

export const mobileBreakpoint = "1024px";

const ns = "arctheme";

window.arcthemeNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value;
}

// Register and initialize AlpineJS
if (!window.Alpine) {
  window.Alpine = Alpine;

  Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineWire, AlpineMorph]);

  Alpine.plugin(persist);
  AlpineGlobals.register(Alpine);
  Alpine.start();
}

export const getAlpineStore = (storeName) => {
  if (window.Alpine && window.Alpine.store) {
    const store = window.Alpine.store(storeName);
    if (store) {
      return store;
    }
  }
  return null;
};

const component_list = [
  { name: "theme__textwithmedia", element: SectionTextWithImage },
  { name: "theme__testimonials", element: SectionTestimonials },
  { name: "theme__banner", element: SectionBanner },
  { name: "theme__hero-banner", element: SectionHeroBanner },
  { name: "theme__featured-categories", element: SectionFeaturedCategories },
  { name: "theme__collection", element: ExtendedCollection },
  { name: "theme__collection-banner", element: SectionCollectionBanner },
  { name: "theme__elements-product-status", element: ProductStockStatus },
  { name: "theme__newsletter-subscribe", element: SectionNewsletterSubscribe },
  { name: "theme__recommendedproducts", element: RecommendedProductList },
  {
    name: "theme__ecommerce-product-gallery",
    element: ProductGalleryComponent,
  },
  { name: "theme__elements-accordion", element: Accordion },
  { name: "theme__multicolumn", element: MulticolumnBlockSection },
  { name: "theme__collectionfilters", element: CollectionFilters },
  { name: "theme__ecommerce-find-in-store", element: FindInStoreBlock },
  { name: "theme__ecommerce-track-your-order", element: TrackYourOrder },
  { name: "theme__nosto", element: NostoComponent },
  { name: "theme__videocontainer", element: VideoContainerSection },
  { name: "theme__storelocator", element: StoreLocatorPage },
  { name: "theme__styleguide", element: StyleguidePage },
  { name: "theme__navigation-header", element: HeaderComponent },
  { name: "theme__ecommerce-minicart", element: MinicartComponent },
  { name: "theme__ecommerce-cart", element: CartComponent },
  { name: "theme__navigation-promobar", element: PromobarComponent },
  { name: "theme__navigation-footer", element: FooterComponent },
  {
    name: "theme__section-featured-companies",
    element: SectionFeaturedCompanies,
  },
  { name: "theme__section-marquee", element: MarqueeSection },
  { name: "theme__block-tags", element: TagListBlock },
  { name: "theme__ecommerce-reviews-list", element: ReviewsListComponent },
  {
    name: "theme__list-recently-viewed-products",
    element: RecentlyViewedProductsList,
  },
  { name: "theme__product-swatches", element: ProductSwatchesComponent },
  { name: "theme__wishlist", element: WishlistComponent },

  // Below add new client components that are extended (name should be theme__<client>-<area>-<component>)
  { name: "theme__mbh-faq", element: SectionFAQ },
  { name: "theme__mbh-navigation-megamenu", element: MegamenuBlock },
  { name: "theme__mbh-search-main", element: SearchResultsPageComponent },
  { name: "theme__mbh-list-products", element: ProductsList },
  { name: "theme__mbh-products-payments", element: ProductPaymentsComponent },
  {
    name: "theme__mbh-products-add-to-cart",
    element: ProductAddToCartComponent,
  },
  { name: "theme__mbh-delivery-images", element: SectionDeliveryImages },
  { name: "theme__mbh-list-blogs", element: BlogsList },
  { name: "theme__mbh-recipe-detail", element: RecipeDetailPageComponent },
  {
    name: "theme__mbh-recipe-inclusions",
    element: RecipeInclusionProductsList,
  },
  {
    name: "theme__mbh-recipe-recommendations",
    element: RecipeRecommendationsList,
  },
  { name: "theme__mbh-recipe-slider", element: RecipeSliderList },
  { name: "theme__mbh-featured-recipe", element: SectionFeaturedRecipe },
  { name: "theme__mbh-featured-blog", element: SectionFeaturedBlog },
  { name: "theme__mbh-recipe-reviews", element: SectionRecipeReviews },
  { name: "theme__mbh-recipe-listing", element: RecipeListingPageComponent },
  { name: "theme__mjbale-navigation-footer", element: FooterComponent },
  { name: "theme__mjbale-social-links", element: SocialLinksComponent },
  { name: "theme__block-logo", element: LogoComponent },
  { name: "theme__block-title", element: TitleComponent },
  { name: "theme__block-description", element: DescriptionComponent },
  { name: "theme__block-button", element: ButtonComponent },
  { name: "theme__product-tile", element: ProductTileComponent },
  { name: "theme__mjbale-category-banner", element: SectionCategoryBanner },
  { name: "theme__mjbale-collection-header", element: SectionCollectionHeader },
  {
    name: "theme__mjbale-search-results-header",
    element: SectionSearchResultsHeader,
  },
  { name: "theme__header-account-popup", element: AccountPopupComponent },
  { name: "theme__mjbale-completethelook", element: CompleteTheLookComponent },
  {
    name: "theme__mjbale-search-header-results",
    element: HeaderSearchComponent,
  },
  { name: "theme__mjbale-collection-main", element: CollectionPageComponent },
  {
    name: "theme__mjbale-collection-toolbar",
    element: CollectionToolbarComponent,
  },
  { name: "theme__pdp-main", element: PDPMainComponent },
  { name: "theme__blog-tile", element: BlogArticleTileComponent },
  { name: "theme__page-header", element: SectionPageHeader },
  { name: "theme__mbh-timeline", element: SectionTimeline },
  { name: "theme__mbh-collectionlist", element: SectionCollectionList },
  {
    name: "theme__mbh-customer-addresses",
    element: CustomerAddressesComponent,
  },
  {
    name: "theme__mbh-products-variant-select",
    element: ProductVariantSelectComponent,
  },
  {
    name: "theme__mbh-product-monogram",
    element: ProductMonogramComponent,
  },
  { name: "theme__mbh-contact-us-form", element: ContactUsComponent },
  { name: "theme__product-inclusions", element: ExtendedProductInclusions },
  {
    name: "theme__mbh-account-select-menu",
    element: ExtendedMobileAccountMenuComponent,
  },
  { name: "theme__shipping-calculator", element: ExtendedShippingCalculator },
  { name: "theme__articles", element: ExtendedArticles },
  { name: "theme__mbh-article", element: ExtendedArticle },
  { name: "theme__mbh-google-rating", element: GoogleRatingComponent },
  { name: "theme__mbh-giftfinder", element: GiftFinderComponent },
  { name: "theme__mbh-signup-popup", element: SignupPopupComponent },
  { name: "theme__mbh-signup-widget", element: FoodClubSignupComponent },
  { name: "theme__account-landing", element: AccountLandingComponent },

];

if (Shopify.designMode) {
  document.addEventListener("shopify:section:load", function () {
    initObserverAnimation();
    renderReactBlocks();
  });

  document.addEventListener("shopify:section:unload", function () {
    initObserverAnimation();
    renderReactBlocks();
  });

  document.addEventListener("shopify:block:select", function () {
    initObserverAnimation();
    renderReactBlocks();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  initObserverAnimation();
  renderReactBlocks();
  setTimeout(function () {
    document.body.classList.add('page-transition');
  }, 300); 
});

window.addEventListener('beforeunload', function () {
  document.body.classList.remove('page-transition');
});

document.addEventListener("liquid-ajax-cart:request-end", (event) => {
  renderReactBlocks();
  setTimeout(function () {
    document.body.classList.add('page-transition');
  }, 300); 
});

function renderReactBlocks() {
  let node = "";

  function decodeHTMLEntities(text) {
    const entities = {
      "&amp;": "&",
      "&quot;": '"',
      "&#39;": "'",
      "&lt;": "<",
      "&gt;": ">",
      "&nbsp;": " ",
      // Add more entities if necessary
    };
    return text.replace(/&[^\s;]+;/g, (match) => entities[match] || match);
  }

  function stripHTMLTags(text) {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

  function sanitizeJSON(jsonString) {
    try {
      // Remove line breaks, tabs, and extra spaces
      let sanitizedString = jsonString
        .replace(/\\n/g, "")
        .replace(/\\t/g, "")
        .trim();
      // Decode HTML entities
      sanitizedString = decodeHTMLEntities(sanitizedString);
      // Strip HTML tags
      sanitizedString = stripHTMLTags(sanitizedString);
      // Remove invalid trailing commas
      sanitizedString = sanitizedString.replace(/,\s*([\]}])/g, "$1");
      return sanitizedString;
    } catch (error) {
      console.error(`Error sanitizing JSON string: ${error.message}`);
      return null;
    }
  }

  try {
    let propsDataJSON = null;
    if (component_list.length > 0) {
      for (let i = 0; i < component_list.length; i++) {
        const nodes = document.querySelectorAll(
          "[data-theme-id=" + component_list[i].name + "]",
        );

        if (nodes.length > 0) {
          for (let j = 0; j < nodes.length; j++) {
            node = component_list[i].name;

            let node_data_props = null;
            var injected = nodes[j].getAttribute("injected");

            if (!injected) {
              let propsNode =
                nodes[j].parentElement.querySelector("#react-data");

              if (propsNode) {
                propsDataJSON = propsNode.getAttribute("data-injected-props");
                const sanitizedPropsDataJSON = sanitizeJSON(propsDataJSON);
       
                if (sanitizedPropsDataJSON) {
                  try {
                    node_data_props = JSON.parse(sanitizedPropsDataJSON);
                  } catch (parseError) {
                    console.log(sanitizedPropsDataJSON);
                    console.error(
                      `JSON parse error on node ${node}: ${parseError.message}`,
                    );
                    continue;
                  }
                  propsNode.remove();
                } else {
                  console.error(`Sanitization failed for node ${node}`);
                  continue;
                }
              } else {
                if (nodes[j].getAttribute("data-block-props")) {
                  propsDataJSON = nodes[j].getAttribute("data-block-props");
                  const sanitizedPropsDataJSON = sanitizeJSON(propsDataJSON);

                  if (sanitizedPropsDataJSON) {
                    try {
                      node_data_props = JSON.parse(sanitizedPropsDataJSON);
                    } catch (parseError) {
                      console.error(
                        `JSON parse error on node ${node}: ${parseError.message}`,
                      );
                      continue;
                    }
                    nodes[j].remove();
                  } else {
                    console.error(`Sanitization failed for node ${node}`);
                    continue;
                  }
                }
              }

              const Element = component_list[i].element;

              ReactDOM.createRoot(nodes[j]).render(
                <React.StrictMode>
                  <Element settings={node_data_props} />
                </React.StrictMode>,
              );

              if (nodes[j]) {
                nodes[j].setAttribute(
                  "id",
                  component_list[i].name + "-injected",
                );
                nodes[j].setAttribute("injected", true);
                nodes[j].removeAttribute(
                  "data-block-props",
                  component_list[i].name + "-injected",
                );
              }
            }
          }
        }
      }
    }
  } catch (e) {
    console.error(`Error on node ${node}`);
    console.error(e.message);
    console.error("Error Injecting React Components");
  }
}

function initObserverAnimation() {
  var observer = new IntersectionObserver(
    function (entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animationDelay =
            entry.target.dataset.animationDelay || "0s";
          entry.target.classList.add("start-animation");
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 0.1,
    },
  );

  // Target the elements to animate
  var elementsToAnimate = document.querySelectorAll(".animated-element");
  elementsToAnimate.forEach(function (element) {
    observer.observe(element);
  });
}
