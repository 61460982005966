import React, {useSyncExternalStore} from "react";

import {EcommTrackOrder} from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/TrackOrder/TrackOrder";
import trackorder from "../js/alpine/components/trackorder";
import ReviewsList from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/ReviewsList/ReviewsList";
import hasher from "../js/alpine/stores/hasher";
import ExtendedMinicart from "../src/stories/components/Minicart/ExtendedMinicart";
import ExtendedCart from "../src/stories/components/ExtendedCart/ExtendedCart";
import Collection from "../src/stories/ecommerce/Collection/Collection";
import CollectionSidebar from "../src/stories/ecommerce/Collection/CollectionSidebar";
import CollectionToolbar from "../src/stories/ecommerce/Collection/CollectionToolbar";
import SearchResults from "../src/stories/ecommerce/Search/SearchResults";
import ExtendedHeaderSearch from "../src/stories/ecommerce/HeaderSearch/ExtendedHeaderSearch";
import AccountLanding from "@src/stories/ecommerce/Account/AccountLanding";



export function MinicartComponent({ ...props }) {
    return (
        <>
            <ExtendedMinicart
                hampers={props.settings.hampers}
                settings={props.settings.section_settings}
                shipping_id={props.settings.shipping_cost_id}
                gift_wrap_types={props.settings.gift_wrap_types}
                card_types={props.settings.card_types}
            />
        </>
    )
}
export function CartComponent({ ...props }) {
    return (
        <>
            <ExtendedCart
                customer={props.settings.customer}
                card_types={props.settings.card_types}
                gift_wrap_types={props.settings.gift_wrap_types}
                hampers={props.settings.hampers}
                shipping_id={props.settings.shipping_cost_id}
                logged_in_threshold={props.settings.logged_in_threshold}
                logged_out_threshold={props.settings.logged_out_threhold}
                free_price_bypass={props.settings.free_price_bypass}
                settings={props.settings.section_settings}
                shipping_rates={props.settings.shipping_rates}
                translations={props.settings.translations}
                enable_multi={props.settings.enable_multi}
            />
        </>
    )
}
export function ReviewsListComponent({ ...props })
{
    return (
        <>
            <ReviewsList reviews={[
                {
                    author: "John Certeza-Vella",
                    content: "This product is freaking insane.",
                    date: "19th March, 2024"
                }
            ]} settings={props.settings.section}/>
        </>
    )
}
export function TrackYourOrder({ ...props }) {

    async function orderCheck(id) {
        trackorder.component().order = id;
        await hasher.store().dispatchHash();
        await trackorder.component().getOrderTrackingInfo(id);
    }

    function useGetOrder() {
        return useSyncExternalStore(trackorder.component().subscribe, trackorder.component().getOrder);
    }

    return (
        <>
            <EcommTrackOrder
                title={'Track Your Order'}
                description={'Please enter your order number below to find tracking information about your order.'}
                buttonText={'Search'}
                clickEvent={orderCheck}
                orderData={useGetOrder()}
            />
        </>
    )
}

// Not in Core
export function CollectionPageComponent({ ...props }) {
    return (
        <Collection settings={props.settings} />
    )
}

export function SearchResultsPageComponent({ ...props }) {
    return (
        <SearchResults settings={props.settings} />
    )
}

export function HeaderSearchComponent({ ...props }) {
    return (
        <ExtendedHeaderSearch settings={props.settings} />
    )
}

export function CollectionSidebarComponent({ ...props }) {
    return (
        <CollectionSidebar settings={props.settings} />
    )
}

export function CollectionToolbarComponent({ ...props }) {
    return (
        <CollectionToolbar settings={props.settings} />
    )
}

export function AccountLandingComponent({ ...props }) {
    return (
        <AccountLanding settings={props.settings} />
    )
}
