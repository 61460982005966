import React, { useState, useEffect } from 'react';

import ExtendedImage from '../../../elements/ExtendedImage/ExtendedImage';
import { addToCart, updateItems, updateItem  } from "frontend/js/useShopifyCart";

const ImagePlaceholderSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">
      <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"/>
      <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"/><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"/>
  </svg>
)


export default function CartItems ({ cartItems, hampers, show_total_price = true }) {
  const [openHampers, setOpenHampers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mergedLineItems, setMergedLineItems] = useState([])

  async function handleQuantityUpdate(id, quantity) {
    if (isLoading) return;

    setIsLoading(true);
    await updateItem({ id, quantity});
    setIsLoading(false);
  }

  async function handleRemoveAllItems(id) {
    if (isLoading) return;
    setIsLoading(true);
    console.log(id);
    const updates = {}

    cartItems.filter(item => item.id == id).map( async ({key}) => {
      updates[`${key}`] = 0;
    })
    
    await updateItems({ updates });
    setIsLoading(false);
  }


  useEffect(() => {

    function mergeLineItems(lineItems) {
      const mergedItemsMap = new Map();
    
      lineItems.forEach(({ id, variant_id, quantity, ...rest }) => {
        const key = `${id}-${variant_id}`;
        
        if (!mergedItemsMap.has(key)) {
          mergedItemsMap.set(key, { id, variant_id, quantity, ...rest });
        } else {
          mergedItemsMap.get(key).quantity += quantity;
        }
      });
    
      return Array.from(mergedItemsMap.values())
      .map(({ properties, ...item }) => item)
      .sort((a, b) => a.id - b.id || a.variant_id - b.variant_id);

    }
      // Cart Items are merged for visual purposes on minicart because each line item even if they have the same properties are added as a unique line item for the cart journey 
    setMergedLineItems(mergeLineItems(cartItems))
  }, [cartItems]);

  async function handleAddItem(item) {
    if (isLoading) return;
      setIsLoading(true);
        const { 
          properties: { 
            _Chilled_Delivery: chilled, 
            _delivery_date_end: deliveryEnd, 
            _delivery_date_start: deliveryStart 
          } = {}, 
          id 
        } = item;
      const uniqueId = `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      const propertiesToAdd = {
        "_itemIndex": `${uniqueId}-${id}`,
        ...(chilled && { "_Chilled_Delivery": chilled }),
        ...(deliveryEnd && { "_delivery_date_end": deliveryEnd }),
        ...(deliveryStart && { "_delivery_date_start": deliveryStart }),
      };
      await addToCart({ id, quantity: 1, properties: propertiesToAdd });
      setIsLoading(false);
  }


  function toggleHamperDetails(id) {
    const copy = openHampers.concat([]);

    if (copy.includes(id)) {
      const index = copy.indexOf(id)
      delete copy[index];

    } else {
      copy.push(id)
    }

    setOpenHampers(copy);
  }

  if (!mergedLineItems.length) return;

  return (
    <ul
      id="items"
      className="items-list px-[16px] flex flex-col flex-grow"
    >
      {mergedLineItems.map((line_item, index) => {

        const indexLineItem = index + 1;
        const line_item_price = line_item.price / 100;
        const icon = openHampers.includes(line_item.id)
          ? 'icon-chevron-up'
          : 'icon-chevron-down';


        const bundleComponents = line_item.properties?._compiledItems && line_item.properties?._compiledItems.split(',');

        return (
          <li
            key={`${line_item.id}_${index}`}
            data-id={indexLineItem}
            data-line={indexLineItem}
            className="item"
          >         
              <a
                href={"/products/" + line_item.handle}
                className='item-details__image-wrap'
              >
                
                  { !line_item.image && <ImagePlaceholderSVG/> }
                  { line_item.image && ( 
                    <ExtendedImage
                      imageUrl={line_item.image}
                      alt={line_item.product_title}
                      isLazy={false}
                      classes={'item-details__image'}
                    />
                  )}
              </a>
           
          
            <div className={`item-details flex-auto gap-2`}>
              <div className="item-details__title b3">
                <span className={`item-details__title-product`}>
                  {line_item.product_title}
                </span>

                <div className={`item__remove b3 ml-auto leading-[1px] hidden lg:block`}>
                  <span
                    className='icon-trash text-2xl'
                    onClick={() => handleRemoveAllItems(line_item.id)}
                  ></span>
                </div>
              </div>

              <div className='flex gap-6'>
                <a
                  href={"/products/" + line_item.handle}
                  className='item-details__image-wrap mobile'
                >
                  <ExtendedImage
                    imageUrl={line_item.image}
                    alt={line_item.product_title}
                    isLazy={false}
                    classes={'item-details__image'}
                  />
                </a>

                <div className={'flex flex-col lg:flex-row lg:items-center grow'}>
                  <div className='max-lg:flex max-lg:items-center'>
                    {!show_total_price ? (
                      <>
                        <h4 className='item__price'>
                          ${line_item_price.toFixed(2)}
                        </h4>

                        {line_item.compare_at_price &&
                          <h4 className='item__price'>
                            ${line_item.compare_at_price / 100}
                          </h4>
                        }
                      </>
                    ) : (
                      <>
                        <h4 className='item__price'>
                          ${(line_item_price * line_item.quantity).toFixed(2)}
                        </h4>

                        {line_item.compare_at_price &&
                          <h4 className='item__price'>
                            ${(line_item.compare_at_price / 100) * line_item.quantity}
                          </h4>
                        }
                      </>
                    )}

                    <a className={`item__remove b3 ml-auto leading-[1px] lg:hidden`}>
                      <div  onClick={() => handleQuantityUpdate(line_item.key, 0)}>
                        <span className='icon-trash text-2xl'></span>
                      </div>
                    </a>
                  </div>

                  <div className='lg:ml-auto max-lg:mt-3'>
                    <div className="flex items-center w-full max-w-[122px] h-[48px] border border-mb-neutral-500 rounded py-2 px-5">
                      <button
                        disabled={isLoading}
                        className="border-none p-0 h-auto leading-[1px]"
                        onClick={() => handleQuantityUpdate(line_item.key, 0)}
                      >
                        <span className="icon-minus text-2xl text-black"></span>
                      </button>

                      <input
                        key={`${line_item.id}_${index}_${line_item.quantity}`}
                        type="number"
                        defaultValue={line_item.quantity || 0}
                        className='extended-minicart__quantity-input'
                      />

                      <button
                        disabled={isLoading}
                        className="border-none p-0 h-auto leading-[1px]"
                        onClick={() => handleAddItem(line_item)}
                      >
                        <span className="icon-plus text-2xl text-black"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="text-xs text-mb-red"
                data-ajax-cart-errors={`${line_item.key}`}
              ></div> */}

              {line_item.properties?._recipient_name &&
                <div className='text-sm'>
                  Recipient: {line_item.properties?._recipient_name}
                </div>
              }

              {line_item.properties?.groupId &&
                <div className='text-sm'>
                  Consignment: {line_item.properties?.groupId}
                </div>
              }

              {line_item.properties?.price &&
                <div className='text-sm'>
                  Shipping Price: {line_item.properties?.price}
                </div>
              }

              {bundleComponents && bundleComponents.length > 0 && 
                <div>
                   { bundleComponents.map((item) => {
                          return ( 
                            <div className='text-sm'> {item} </div>
                          )
                    })}
                </div>
              }

              {(hampers[line_item.id]?.length > 0) &&
                <div>
                  <div
                    className='flex items-center cursor-pointer'
                    onClick={() => toggleHamperDetails(line_item.id)}
                  >
                    <span className={`${icon} text-2xl mr-2`}></span>
                    <span className='underline text-sm'>
                      View items in hamper
                    </span>
                  </div>

                  {openHampers.includes(line_item.id) &&
                    <div className='text-xs font-medium mt-4'>
                      <ul className='flex flex-col gap-2 mb-2'>
                        {hampers[line_item.id].map((component, index) => (
                          <li key={`${line_item.id}_${index}`}>
                            1 x {component}
                          </li>
                        ))}
                      </ul>

                      {hampers[line_item.id].length > 4 &&
                        <a
                          href={`/products/${line_item.handle}`}
                          target="_blank"
                          className='text-sm font-normal underline'
                        >
                          View more in product page
                        </a>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          </li>
        )
      })}
    </ul>
  );
}