
import React, { useState, useEffect } from "react";
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";

const ExtendedProductMonogram = ({ props }) => {
  const { block_settings: { text_font_1, text_font_2, text_label_1, text_label_2, show_second, text_property_name, font_property_name } } = props.settings;
  let options = [
    {
        type: text_label_1,
        font: text_font_1
    },
    {
        type: text_label_2,
        font: text_font_2
    }
  ]
  const [ currentOption, setCurrenOption] = useState(options[0].type)
  const [ noMonogram, setnoMonogram] = useState(false)

  return (
    <div className={'product-monogram flex flex-col'}>
        <div className={`flex flex-col gap-2`}> 
            <div className={`text-[14px]`}> 
                {`Step 1 Select Font`}
            </div>
            { !noMonogram && 
            <input
                type="text"
                data-name={`${font_property_name}`}
                name={`properties[${font_property_name}]`}
                id={`line-item-${font_property_name}`}
                value={currentOption}  
                readOnly 
                className="product-monogram__input line-item-property hidden"
            /> }
            <div className="flex flex-row w-100 gap-2"> 
                    {options.map((option) => (
                        <div key={option?.type} style={{ fontFamily: option.font }} className={`product-monogram__option !w-1/2 button ${option.type == currentOption ? `button-primary`: `button-secondary !font-[500]` } `} onClick={()=> setCurrenOption(option.type)}> 
                            {option.type}
                        </div>
                    ))}
            </div>
        </div>
        { !show_second ?  
            <div className={`flex flex-col gap-2`}> 
                  <div className={`text-[14px]`}> 
                      {`Step 2 Enter Your Monogram`}
                  </div>
                  <input
                      type="text"
                      data-name={`${text_property_name}-1`}
                      name={`properties[${text_property_name}-1]`}
                      id={`line-item-${text_property_name}-1`}
                      placeholder="Enter your monogram"
                      value={noMonogram ? "" : undefined}
                      disabled={noMonogram} 
                      className={`product-monogram__input ${noMonogram ? '' : 'line-item-property'}`}
                  />
            </div>
        : 
        <div className={`flex flex-col gap-2`}> 
            <div className={`text-[14px]`}> 
                {`Step 2 Enter Your First Monogram`}
            </div> 
            <input
                type="text"
                data-name={`${text_property_name}-1`}
                name={`properties[${text_property_name}-1]`}
                id={`line-item-monogram-text-1`}
                placeholder="Enter your monogram"
                value={noMonogram ? "" : undefined} 
                disabled={noMonogram} 
                className={`product-monogram__input ${noMonogram ? '' : 'line-item-property'}`}
            />

            <div className={`text-[14px] mt-4`}> 
                {`Step 3 Enter Your Second Monogram`}
            </div> 
            <input
                type="text"
                data-name={`${text_property_name}-2`}
                name={`properties[${text_property_name}-2]`}
                id={`line-item-${text_property_name}-2`}
                value={noMonogram ? "" : undefined}
                disabled={noMonogram} 
                placeholder="Enter your monogram"
                className={`product-monogram__input ${noMonogram ? '' : 'line-item-property'}`}
            />
        </div>
        }
  
        <div className="flex justify-center"> 
            <ExtendedCheckbox                     
                name={'no_monogram'}
                label={'No Monogram Required'}
                checked={noMonogram}
                onChange={()=> { setnoMonogram(!noMonogram) }}
            />

        </div>
          
   
    </div>
  )
}


export default ExtendedProductMonogram;



