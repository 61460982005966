import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import React, { useState } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import ExtendedCollapsible from '@src/stories/components/ExtendedCollapsible/ExtendedCollapsible';
import './GiftFinder.scss'

const GiftFinder = ({ settings }) => {
  const { section, blocks } = settings;
  const isMobile = useMobileBreakpoint('1024px');

  // Set up state for each dropdown dynamically using an object
  const [selections, setSelections] = useState(
    blocks.reduce((acc, block, id) => {
      acc[`dropdown-${id}`] = block.settings.dropdown_options
        .split(",")[0]
        .trim(); // Default to the first option in each block
      return acc;
    }, {}),
  );

  const handleDropdownChange = (id, value) => {
    setSelections((prevSelections) => ({
      ...prevSelections,
      [`dropdown-${id}`]: value,
    }));
  };

  const handleSubmit = () => {
    // Handle the submit logic here (e.g., filtering products)
    console.log("Selections:", selections);
  };

  return (
    <>
    {
      isMobile &&     
      <div className="gift-finder-mobile">
              <ExtendedCollapsible
                key={'gift-finder-mobile-bar'}
                title={'Find the perfect gift'}
                openIcon='icon-chevron-down'
                desktopDisabled
            >
              <div className="gift-finder-mobile__container"> 
                       { blocks.map((filter, id) =>{ 
                        const options = filter.settings.dropdown_options
                        .split(",")
                        .map((option) => ({
                          value: option.trim(),
                          label: option.trim(),
                        }))
                        return (
                          <ExtendedCollapsible
                                    nested
                                    tier={1}
                                    title={options[0]?.label}
                                    openIcon='icon-chevron-down'
                                    desktopDisabled
                              >
                                          <ul className="gift-finder-accordion-item"> 
                                            {options.map((item)=> (
                                                 <li className="flex justify-start pl-[12px]"> { item.label}</li>

                                            ))}
                                         
                                          </ul>
                                     
                              </ExtendedCollapsible>)
                       } )
                          }
                               <button
                                          className={`button button-${section.button_style}`}
                                          onClick={handleSubmit}
                                        >
                                          {section.button_text}
                                        </button>
              </div>
          </ExtendedCollapsible>
    </div>
    }
    { !isMobile && 
      <div className={`gift-finder ${settings?.section?.layout == 'inline-layout' ? settings?.section?.layout : '' }`}>

        <div className="flex items-center gap-[16px] px-[20px] py-[8px]">
          {blocks.map((filter, id) => (
            <div key={id} className="grid-item">
              {/* Gift Type Filter */}
              <div className="flex flex-row items-center">
                <label htmlFor={`dropdown-${id}`} className="w-auto">
                  {filter.settings.dropdown_label}
                </label>
                <ExtendedSelectField
                  id={`dropdown-${id}`}
                  className="gift-finder__select w-[150px]"
                  value={selections[`dropdown-${id}`]}
                  onChange={(e) => {
                    console.log(e);
                    handleDropdownChange(id, e.value);
                  }}
                  options={filter.settings.dropdown_options
                    .split(",")
                    .map((option) => ({
                      value: option.trim(),
                      label: option.trim(),
                    }))}
                />
              </div>
            </div>
          ))}
        </div>

        <button
          className={`button button-${section.button_style}`}
          onClick={handleSubmit}
        >
          {section.button_text}
        </button>
      </div>
    }
    </>
  );
};

export default GiftFinder;
