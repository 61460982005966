import React, { useState } from 'react';
import { clsx } from 'clsx';

type ExtendedTextareaFieldProps = {
  id?: string,
  name?: string,
  placeholder?: string,
  label?: string,
  value?: string | number,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  disableEmoji?: boolean,
  onChange?: Function,
  maxCharacters?: number
}

export default function ExtendedTextareaField ({
  id,
  name,
  placeholder,
  label,
  value,
  required,
  disabled,
  readonly,
  hasError,
  errorMessage,
  disableEmoji = false,
  onChange = () => {},
  maxCharacters = 0
}: ExtendedTextareaFieldProps) {
  const [text, setText] = useState(value);
  function handleChange (e) {
    const { value } = e.target;
    let cleanedValue = value;
    if(disableEmoji) {
      const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;
      cleanedValue = value.replace(emojiRegex, "");
    }
    setText(cleanedValue);
    
    if (onChange) onChange(e);
  }

  return (
    <div>
      {label &&
        <label
          htmlFor={id}
          className="form-field__label"
        >
          {label}
        </label>
      }
      <textarea
        id={id}
        className={clsx('form-field', {
          'has-error': hasError,
          'disabled': disabled
        })}
        name={name}
        defaultValue={value}
        value={text} 
        placeholder={placeholder}
        required={required}
        readOnly={readonly}
        disabled={disabled}
        maxLength={maxCharacters == 0 ?  null : maxCharacters}
        onChange={(e) => handleChange(e)}
      />

      {(hasError && errorMessage) &&
        <div className="form-field__error-message">
          {errorMessage}
        </div>
      }

      {maxCharacters != 0 && !disabled && 
        ( <div className="flex justify-end text-[12px] font-[400]">
          {`${text.length}/${maxCharacters}`}  
        </div> ) 
      }
    </div>
  );
};
