import React, {useState, useEffect} from 'react';
import ExtendedMegamenuFeaturedImage from "./ExtendedMegamenuFeaturedImage";
import ExtendedMegamenuPromoBlock  from "@stories/components/Megamenu/ExtendedMegamenuPromoBlock";
import ExtendedProductTile from "@stories/components/ExtendedProductTile/ExtendedProductTile";
import MegamenuLinks from "./ExtendedMegamenuLinks";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { clsx } from "clsx";
import {mobileBreakpoint} from "../../../../entrypoints/theme";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";


const ExtendedMegamenu = ({ settings, menuItems, id, state, layout = '4column_dropdown', translations, localization }) => {
    const [anim, setAnim] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const showInitialWhenNoLinks = true;
    const animClass = state === id ? "slide-in-element" : "slide-out-element";
    const { section_settings: { megamenu_layout } } = settings;
    const columnBlocks = menuItems?.blocks.filter(block => block.type == 'column').map(item => 
        item.settings.column.split(',').map(column => column.trim())
      );
      
    const getInitialPromoDropdownBlocks = () => {
        return menuItems.blocks.reduce((initialBlocks, block) => {
                if(block.type == 'images') {
                    for (let i = 1; i <= 4; i++) {
                        const imageKey = `image_${i}`;
                        const mobileImageKey = `mobile_image_${i}`;
                        const titleKey = `title_${i}`;
                        const subtitleKey = `subtitle_${i}`;
                        const urlKey = `url_${i}`;
                        const buttonTextKey = `button_text_${i}`;
                        const colorSchemaKey = `color_schema`;

                        if (block.settings[imageKey]) {
                            initialBlocks.push({
                                image: block.settings[imageKey],
                                mobile_image: block.settings[mobileImageKey],
                                title: block.settings[titleKey],
                                url: block.settings[urlKey],
                                button_text: block.settings[buttonTextKey],
                                subtitle: block.settings[subtitleKey],
                                color_schema: block[colorSchemaKey]
                            });
                        }
                    }
                }
            return initialBlocks;
        }, []);
    };

    const getInitialProducts = () => {
        return menuItems.blocks.filter(block => block.type == 'product' && block.product?.selected_or_first_available_variant);
    };

    const [featuredImageBlocks, setFeaturedImageBlocks] = useState([]);
    const [promoDropdownBlocks, _setPromoDropdownBlocks] = useState(getInitialPromoDropdownBlocks());
    const [productBlocks, _setProductBlocks] = useState(getInitialProducts());

    useEffect(() => {
        setIsAnimating(true);
    }, [state, id]);

    const onAnimationEnd = () => {
        if (state !== id) {
            setAnim(false);
            setIsAnimating(false);
        } else {
            setAnim(true);
            setIsAnimating(false);
        }
    };

    const handleLinkHover = (link, setInitial) => {
        if (link?.level <= 2) {
                setHoveredLink(link);
                setActiveLink(link.handle);
                updateFeaturedImageBlocks(link);
        }

        if (showInitialWhenNoLinks) {
            if (setInitial) {
                setFeaturedImageBlocks(getInitialFeaturedImageBlocks());
            }
        }
    };


    // Function to add active class based on handle
    function setActiveLink(handle) {
        const activeLink = document.querySelector('.megamenu__link-container.active');
        if (activeLink) {
            activeLink.classList.remove('active');
        }

        // Find the div with the corresponding data-link-id
        const linkDiv = document.querySelector(`div[data-link-id='${handle}']`);

        // Check if the div exists
        if (linkDiv) {
            // Add the active class
            linkDiv.classList.add('active');
        }
    }


    const getInitialFeaturedImageBlocks = () => {
        return menuItems.blocks.reduce((initialBlocks, block) => {
            if (block.type === 'toparea') {
                for (let i = 1; i <= 4; i++) {
                    const imageKey = `image_${i}`;
                    const mobileImageKey = `mobile_image_${i}`;
                    const titleKey = `title_${i}`;
                    const urlKey = `url_${i}`;
                    const buttonTextKey = `button_text_${i}`;


                    if (block.settings[imageKey]) {
                        initialBlocks.push({
                            image: block.settings[imageKey],
                            mobile_image: block.settings[mobileImageKey],
                            title: block.settings[titleKey],
                            url: block.settings[urlKey],
                            button_text: block.settings[buttonTextKey],
                        });
                    }
                }
            }
            return initialBlocks;
        }, []);
    };

    const updateFeaturedImageBlocks = (link) => {
        const updatedBlocks = menuItems.blocks.reduce((blocks, block) => {
            if (block.settings.title === link.title) {
                for (let i = 1; i <= 2; i++) {
                    const imageKey = `image_${i}`;
                    const mobileImageKey = `mobile_image_${i}`;
                    const titleKey = `title_${i}`;
                    const urlKey = `url_${i}`;
                    const buttonTextKey = `button_text_${i}`;

                    if (block.settings[imageKey]) {
                        blocks.push({
                            image: block.settings[imageKey],
                            mobile_image: block.settings[mobileImageKey],
                            title: block.settings[titleKey],
                            url: block.settings[urlKey],
                            button_text: block.settings[buttonTextKey],
                        });
                    }
                }
            }
            return blocks;
        }, []);
        setFeaturedImageBlocks(updatedBlocks);
    };

    useEffect(() => {
        setFeaturedImageBlocks(getInitialFeaturedImageBlocks());
    }, []);

    if (state !== id && !anim) {
        return null;
    }

    const LayoutComponent = Layouts[megamenu_layout];
    return (
        <LayoutComponent
            settings={settings}
            menuItems={menuItems}
            id={id}
            state={state}
            animClass={animClass}
            onAnimationEnd={onAnimationEnd}
            hoveredLink={hoveredLink}
            onLinkHover={handleLinkHover}
            featuredImageBlocks={featuredImageBlocks}
            promoDropdownBlocks={promoDropdownBlocks}
            productBlocks={productBlocks}
            translations={translations}
            localization={localization}
            columnBlocks={columnBlocks}
        />
    );
};

const MegamenuLayout2Column1Image = ({ settings, menuItems, id, state, onAnimationEnd, hoveredLink, onLinkHover, promoDropdownBlocks, productBlocks, translations, localization, columnBlocks }) => {
    const firstLevelLinks = menuItems.subMenuItems[0]?.links || [];
    const defaultSecondLevelLinks = firstLevelLinks[0]?.links || [];
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    const [menuState, setMenuState] = useState({
        menuAnimClass: "",
        firstLevelActive: false,
        secondLevelActive: false,
        navigationTitle: "",
        firstMenuTitle: "",
        transitionClass: "",
        primaryLinksAnim: "",
        isSecondLevelVisible: false
    });
    const { section_settings: { subitem_show_label } } = settings;

    const updateMenuState = updates => setMenuState(prevState => ({ ...prevState, ...updates }));
    
    const handleFirstLevelClick = link => {
        if (link.links.length > 0) {
            updateMenuState({
                navigationTitle: link.title,
                transitionClass: "slide-out-left",
                firstLevelActive: false,
                secondLevelActive: true,
                isSecondLevelVisible: true,
                primaryLinksAnim: 'slide-in-from-right'
            });
            window.dispatchEvent(new Event('megamenu-menu-open'));
        }
    };

    const handleBackClick = () => {
        if (menuState.secondLevelActive) {
            updateMenuState({
                transitionClass: "slide-in-left",
                secondLevelActive: false,
                navigationTitle: menuState.firstMenuTitle,
                firstLevelActive: true,
                primaryLinksAnim: 'slide-in-left'
            });

            const drawerElement = document.querySelector('.megamenu__secondary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        } else {
            window.dispatchEvent(new Event('megamenu-menu-close'));
            updateMenuState({ primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-to-right', isFirstLevelActive: true });
            const drawerElement = document.querySelector('.megamenu__primary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        }
    };

    const handleMenuClose = () => {
        updateMenuState({primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-left', firstLevelActive: false, secondLevelActive: false, isSecondLevelVisible: false });
    };

    useEffect(() => {
        const handleMobileMenuClosed = () => handleMenuClose();
        window.addEventListener("mobile-menu-closed", handleMobileMenuClosed);

        if (isMobile && state === id) {
            onLinkHover(null);
            const newTitle = id.replace('header-', '');
            updateMenuState({
                navigationTitle: newTitle,
                firstMenuTitle: newTitle,
                firstLevelActive: true,
                menuAnimClass: "slide-in-from-right",
                primaryLinksAnim: "slide-in-from-right",
                transitionClass: ""
            });
        }

        return () => {
            window.removeEventListener("mobile-menu-closed", handleMobileMenuClosed);
        };
    }, [isMobile, state, id]);

    useEffect(() => {
        if (!isMobile) {
            onLinkHover(menuItems.subMenuItems[0]?.links[0], true);

        }
    }, [menuState]);

    const renderLinks = (links, isSecondary = false) => {

        const flattenedGroupItems = columnBlocks.flat();
        const grouped = links.filter(subItem => flattenedGroupItems.includes(subItem.title));
        // Filter the remaining items (not in groupItems)
        const nonGrouped =  links.filter(subItem  => !flattenedGroupItems.includes(subItem.title));
        const getGroupMatches = (group) => {
            return links.filter(subItem => group.includes(subItem.title));
          };
        
        return   (
            <>
                {nonGrouped.map((subItem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={`megamenu__primary--links`}>
                                <MegamenuLinks
                                    tier={'primary'}
                                    subItem={subItem}
                                    onLinkHover={() => {}}
                                    onLinkLeave={() => {}}
                                    onLinkClick={() => {}}
                                />
                                <div className={`megamenu__secondary--links-container`}>
                                    {subItem.links && subItem.links.map((subItem2, idx) => {  
        
                                        return (
                                            <div className={`megamenu__secondary--links`}>
                                                <div className={`${subItem2.links.length == 0  && idx == 0 ? 'first' : ''}`}>
                                                    <MegamenuLinks
                                                        key={idx}
                                                        tier={ subItem2.links.length > 0 ? 'secondary' : 'tertiary'}
                                                        subItem={subItem2}
                                                        onLinkHover={() => {}}
                                                        onLinkLeave={() => {}}
                                                        onLinkClick={() => {}}
                                                    />
                                                </div>
                                                <div className={`megamenu__tertiary--links-container`}>
                                                    {subItem2.links && subItem2.links.length > 0 && (
                                                        <div className={`megamenu__tertiary--links`}>
                                                            {subItem2.links && subItem2.links.map((subItem3, idx) => (
                                                                <MegamenuLinks
                                                                    key={idx}
                                                                    tier={'tertiary'}
                                                                    subItem={subItem3}
                                                                    onLinkHover={() => {}}
                                                                    onLinkLeave={() => {}}
                                                                    onLinkClick={() => {}}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                        
                                    })}
                                </div>
                                {subItem.links && subItem.links.length > 0 && <div className="megamenu__primary--links-all"> <ElementLink link={subItem.url} text={ subitem_show_label != '' ? `${subitem_show_label}` : `Shop all ${subItem.title}`}/>  </div> }
                            </div>
                        </React.Fragment>
                    );
                })}

                { grouped.length > 0 && columnBlocks.map((group, index) => {
                    return (
                        <React.Fragment key={index}>
                                <div className={`megamenu__primary--links multiple-menu`}>
                                {getGroupMatches(group).map((subItem, index) => (
                                        <>
                                            <MegamenuLinks
                                                tier={'primary'}
                                                subItem={subItem}
                                                onLinkHover={() => {}}
                                                onLinkLeave={() => {}}
                                                onLinkClick={() => {}}
                                            />
                                            <div className={`megamenu__secondary--links-container`}>
                                                {subItem.links && subItem.links.map((subItem2, idx) => {  
                    
                                                    return (
                                                        <div className={`megamenu__secondary--links`}>
                                                            <div className={`${subItem2.links.length == 0  && idx == 0 ? 'first' : ''}`}>
                                                                <MegamenuLinks
                                                                    key={idx}
                                                                    tier={ subItem2.links.length > 0 ? 'secondary' : 'tertiary'}
                                                                    subItem={subItem2}
                                                                    onLinkHover={() => {}}
                                                                    onLinkLeave={() => {}}
                                                                    onLinkClick={() => {}}
                                                                />
                                                            </div>
                                                            <div className={`megamenu__tertiary--links-container`}>
                                                                {subItem2.links && subItem2.links.length > 0 && (
                                                                    <div className={`megamenu__tertiary--links`}>
                                                                        {subItem2.links && subItem2.links.map((subItem3, idx) => (
                                                                            <MegamenuLinks
                                                                                key={idx}
                                                                                tier={'tertiary'}
                                                                                subItem={subItem3}
                                                                                onLinkHover={() => {}}
                                                                                onLinkLeave={() => {}}
                                                                                onLinkClick={() => {}}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                    
                                                })}
                                            </div>
                                            {subItem.links && subItem.links.length > 0 && <div className="megamenu__primary--links-all"> <ElementLink link={subItem.url} text={ subitem_show_label != '' ? `${subitem_show_label}` : `Shop all ${subItem.title}`}/>  </div> }
                                            </>
                                )
                                )}
                        
                            </div>
                        </React.Fragment>
                    );
                })}
            </> 
        );
    }
  
  
    return (
        <nav className={`megamenu-nav`} onAnimationEnd={onAnimationEnd}>
            <div className="relative bg-inherit">
                <div className="megamenu-nav-area">

                    <div className={`megamenu-nav-list ${clsx({
                        'flex-col mobile-menu-drawer h-screen': isMobile,
                        ['relative block']: isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible),
                        [menuState.menuAnimClass]: isMobile
                    })}`}>
                        {isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible) && (
                            <div className="mobile-menu-item b2" onClick={handleBackClick}>
                                <i className="icon-chevron-left"></i>
                                {menuState.navigationTitle}
                            </div>
                        )}
                        <div className={`megamenu__dropdown--links-container ${clsx({
                            [menuState.primaryLinksAnim]: isMobile && menuState.firstLevelActive && !menuState.secondLevelActive,
                            'slide-out-left': isMobile && !menuState.firstLevelActive && menuState.secondLevelActive,
                        })}`}>
                            {renderLinks(firstLevelLinks)}
                        </div>
                        {!isMobile && productBlocks.length > 0 && (
                            <div className="megamenu__featured-items">
                                
                                    {productBlocks.slice(0, 2).map((block, index) => {
                                        return(
                                        <ExtendedMegamenuFeaturedImage currencySymbol={localization?.country.currency_symbol}  translations={translations} image={block?.product?.images[0]} url={block?.product?.url} title={block?.settings?.featured_label || block?.product?.title} index={index} price={block?.product?.price} comparePrice={block?.product?.compare_at_price_max} />
                                        )
                                    })}
                                 
                            </div>)
                        }
                    </div>
            
                </div>
            </div>
        </nav>
    );
};

const MegamenuLayout2Column2Image = ({ settings, menuItems, id, state, onAnimationEnd, hoveredLink, onLinkHover, promoDropdownBlocks, productBlocks, translations, localization, columnBlocks }) => {
    const firstLevelLinks = menuItems.subMenuItems[0]?.links || [];
    const defaultSecondLevelLinks = firstLevelLinks[0]?.links || [];
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    const [menuState, setMenuState] = useState({
        menuAnimClass: "",
        firstLevelActive: false,
        secondLevelActive: false,
        navigationTitle: "",
        firstMenuTitle: "",
        transitionClass: "",
        primaryLinksAnim: "",
        isSecondLevelVisible: false
    });
    const { section_settings: { subitem_show_label } } = settings;

    const updateMenuState = updates => setMenuState(prevState => ({ ...prevState, ...updates }));

    const handleFirstLevelClick = link => {
        if (link.links.length > 0) {
            updateMenuState({
                navigationTitle: link.title,
                transitionClass: "slide-out-left",
                firstLevelActive: false,
                secondLevelActive: true,
                isSecondLevelVisible: true,
                primaryLinksAnim: 'slide-in-from-right'
            });
            window.dispatchEvent(new Event('megamenu-menu-open'));
        }
    };

    const handleBackClick = () => {
        if (menuState.secondLevelActive) {
            updateMenuState({
                transitionClass: "slide-in-left",
                secondLevelActive: false,
                navigationTitle: menuState.firstMenuTitle,
                firstLevelActive: true,
                primaryLinksAnim: 'slide-in-left'
            });

            const drawerElement = document.querySelector('.megamenu__secondary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        } else {
            window.dispatchEvent(new Event('megamenu-menu-close'));
            updateMenuState({ primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-to-right', isFirstLevelActive: true });
            const drawerElement = document.querySelector('.megamenu__primary--links');
            if (drawerElement) {
                const handleAnimationEnd = () => {
                    updateMenuState({ isSecondLevelVisible: false });
                    drawerElement.removeEventListener('animationend', handleAnimationEnd);
                };
                drawerElement.addEventListener('animationend', handleAnimationEnd);
            }
        }
    };

    const handleMenuClose = () => {
        updateMenuState({primaryLinksAnim: 'slide-out-to-right', menuAnimClass: 'slide-out-left', firstLevelActive: false, secondLevelActive: false, isSecondLevelVisible: false });
    };

    useEffect(() => {
        const handleMobileMenuClosed = () => handleMenuClose();
        window.addEventListener("mobile-menu-closed", handleMobileMenuClosed);

        if (isMobile && state === id) {
            onLinkHover(null);
            const newTitle = id.replace('header-', '');
            updateMenuState({
                navigationTitle: newTitle,
                firstMenuTitle: newTitle,
                firstLevelActive: true,
                menuAnimClass: "slide-in-from-right",
                primaryLinksAnim: "slide-in-from-right",
                transitionClass: ""
            });
        }

        return () => {
            window.removeEventListener("mobile-menu-closed", handleMobileMenuClosed);
        };
    }, [isMobile, state, id]);

    useEffect(() => {
        if (!isMobile) {
            onLinkHover(menuItems.subMenuItems[0]?.links[0], true);

        }
    }, [menuState]);

    const renderLinks = (links, isSecondary = false) => {

        const flattenedGroupItems = columnBlocks.flat();
        const grouped = links.filter(subItem => flattenedGroupItems.includes(subItem.title));
        // Filter the remaining items (not in groupItems)
        const nonGrouped =  links.filter(subItem  => !flattenedGroupItems.includes(subItem.title));
        const getGroupMatches = (group) => {
            return links.filter(subItem => group.includes(subItem.title));
          };
        
        return   (
            <>
                {nonGrouped.map((subItem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={`megamenu__primary--links`}>
                                <MegamenuLinks
                                    tier={'primary'}
                                    subItem={subItem}
                                    onLinkHover={() => {}}
                                    onLinkLeave={() => {}}
                                    onLinkClick={() => {}}
                                />
                                <div className={`megamenu__secondary--links-container`}>
                                    {subItem.links && subItem.links.map((subItem2, idx) => {  
        
                                        return (
                                            <div className={`megamenu__secondary--links`}>
                                                <div className={`${subItem2.links.length == 0  && idx == 0 ? 'first' : ''}`}>
                                                    <MegamenuLinks
                                                        key={idx}
                                                        tier={ subItem2.links.length > 0 ? 'secondary' : 'tertiary'}
                                                        subItem={subItem2}
                                                        onLinkHover={() => {}}
                                                        onLinkLeave={() => {}}
                                                        onLinkClick={() => {}}
                                                    />
                                                </div>
                                                <div className={`megamenu__tertiary--links-container`}>
                                                    {subItem2.links && subItem2.links.length > 0 && (
                                                        <div className={`megamenu__tertiary--links`}>
                                                            {subItem2.links && subItem2.links.map((subItem3, idx) => (
                                                                <MegamenuLinks
                                                                    key={idx}
                                                                    tier={'tertiary'}
                                                                    subItem={subItem3}
                                                                    onLinkHover={() => {}}
                                                                    onLinkLeave={() => {}}
                                                                    onLinkClick={() => {}}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                        
                                    })}
                                </div>
                                {subItem.links && subItem.links.length > 0 && <div className="megamenu__primary--links-all"> <ElementLink link={subItem.url} text={ subitem_show_label != '' ? `${subitem_show_label}` : `Shop all ${subItem.title}`}/>  </div> }
                            </div>
                        </React.Fragment>
                    );
                })}

                { grouped.length > 0 && columnBlocks.map((group, index) => {
                    return (
                        <React.Fragment key={index}>
                                <div className={`megamenu__primary--links multiple-menu`}>
                                {getGroupMatches(group).map((subItem, index) => (
                                        <>
                                            <MegamenuLinks
                                                tier={'primary'}
                                                subItem={subItem}
                                                onLinkHover={() => {}}
                                                onLinkLeave={() => {}}
                                                onLinkClick={() => {}}
                                            />
                                            <div className={`megamenu__secondary--links-container`}>
                                                {subItem.links && subItem.links.map((subItem2, idx) => {  
                    
                                                    return (
                                                        <div className={`megamenu__secondary--links`}>
                                                            <div className={`${subItem2.links.length == 0  && idx == 0 ? 'first' : ''}`}>
                                                                <MegamenuLinks
                                                                    key={idx}
                                                                    tier={ subItem2.links.length > 0 ? 'secondary' : 'tertiary'}
                                                                    subItem={subItem2}
                                                                    onLinkHover={() => {}}
                                                                    onLinkLeave={() => {}}
                                                                    onLinkClick={() => {}}
                                                                />
                                                            </div>
                                                            <div className={`megamenu__tertiary--links-container`}>
                                                                {subItem2.links && subItem2.links.length > 0 && (
                                                                    <div className={`megamenu__tertiary--links`}>
                                                                        {subItem2.links && subItem2.links.map((subItem3, idx) => (
                                                                            <MegamenuLinks
                                                                                key={idx}
                                                                                tier={'tertiary'}
                                                                                subItem={subItem3}
                                                                                onLinkHover={() => {}}
                                                                                onLinkLeave={() => {}}
                                                                                onLinkClick={() => {}}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                    
                                                })}
                                            </div>
                                            {subItem.links && subItem.links.length > 0 && <div className="megamenu__primary--links-all"> <ElementLink link={subItem.url} text={ subitem_show_label != '' ? `${subitem_show_label}` : `Shop all ${subItem.title}`}/>  </div> }
                                            </>
                                )
                                )}
                        
                            </div>
                        </React.Fragment>
                    );
                })}
            </> 
        );
    }
  
  
    return (
        <nav className={`megamenu-nav`} onAnimationEnd={onAnimationEnd}>
            <div className="relative bg-inherit">
                <div className="megamenu-nav-area">

                    <div className={`megamenu-nav-list ${clsx({
                        'flex-col mobile-menu-drawer h-screen': isMobile,
                        ['relative block']: isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible),
                        [menuState.menuAnimClass]: isMobile
                    })}`}>
                        {isMobile && (menuState.firstLevelActive || menuState.isSecondLevelVisible) && (
                            <div className="mobile-menu-item b2" onClick={handleBackClick}>
                                <i className="icon-chevron-left"></i>
                                {menuState.navigationTitle}
                            </div>
                        )}
                        <div className={`megamenu__dropdown--links-container ${clsx({
                            [menuState.primaryLinksAnim]: isMobile && menuState.firstLevelActive && !menuState.secondLevelActive,
                            'slide-out-left': isMobile && !menuState.firstLevelActive && menuState.secondLevelActive,
                        })}`}>
                            {renderLinks(firstLevelLinks)}
                        </div>
                        {!isMobile && productBlocks.length > 0 && (
                            <div className="megamenu__product--blocks">
                                  <div className={`megamenu__dropdown--links-primary`}> <span> { settings.section_settings.featured_products_label } </span> </div>
                                <div className={`grid grid-cols-1`}>
                                    {productBlocks.map((block, index) => {
                                        const product = {...block.product};
                                        return(<div className={``} key={index}>
                                                <ExtendedProductTile product={product} translations={translations} currencySymbol={localization?.country.currency_symbol}  hideAddToCart hideWishlist hideTags hideCompare/>
                                        </div>)
                                    })}
                                </div>
                            </div>)
                        }
                        {!isMobile && promoDropdownBlocks.length > 0 && (
                            <div className="megamenu__promo--blocks">
                                <div className={`gap-[20px] grid grid-cols-1 h-full`}>
                                    {promoDropdownBlocks.map((block, index) => (
                                        <div className={`p-4 color-${block.color_schema}`} key={index} style={{ marginBottom: settings.section_settings.image_spacing }}>
                                            <ExtendedMegamenuPromoBlock index={index} blockItem={block} section_settings={settings.section_settings} button_url={settings.url}/>
                                        </div>
                                    ))}
                                </div>
                            </div>)
                        }
                    </div>
            
                </div>
            </div>
        </nav>
    );
};

const Layouts = {
    'featured_items': MegamenuLayout2Column1Image,
    'promotional_block': MegamenuLayout2Column2Image
};

export default ExtendedMegamenu;