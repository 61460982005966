// @ts-nocheck
import React, {useEffect, useState} from "react";

import ProductTileBadges from "./components/ProductTileBadges";
import ProductTileStarRatingsApp from "./components/ProductTileStarRatingsApp";
import ProductTileSaleCountdown from "./components/ProductTileSaleCountdown";
import ProductTileAddToCart from "./components/ProductTileAddToCart";
import ProductTileImage from "./components/ProductTileImage";

import {
  formatPrice,
  formatPriceWithoutTrailingZeros,
  interpolateTranslation,
  appendPrice,
} from "frontend/js/helpers";

const FillHeartSVG = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z" fill="#445B4B" stroke="#445B4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>)
}

type TileProps = {
  product: undefined;
  currentVariant?: undefined;
  addToCartUrl?: undefined;
  starRatingsApp?: "";
  translations?: object;
  hideAddToCart?: boolean;
  hideWishlist?: boolean;
  hideTags?: boolean;
  hideCompare?: boolean;
  upgradeTile?: undefined;
  currencySymbol?: string;
  onClose?: undefined;
  chilled?: boolean,
  delivery_date?: string,
  delivery_date_end?: string,
  active_swatch_url?: string,
  show_color_swatches?: boolean,
  colour_siblings?: array 
};



export default function ExtendedProductTile(props: TileProps) {
  const {
    product,
    currentVariant = "",
    addToCartUrl = "",
    starRatingsApp = "",
    translations = {},
    hideAddToCart = false,
    hideWishlist = false,
    hideTags = false,
    hideCompare = false,
    upgradeTile = 0,
    currencySymbol = "",
    chilled= false,
    delivery_date = "",
    delivery_date_end = "",
    onClose = () => {},
    active_swatch_url = "",
    show_color_swatches = true,
    colour_siblings = []
  } = props;

  const renderSwatches = (swatches) => {
    return (
      <div className="flex flex-wrap items-center justify-center gap-2">
        {swatches.map((swatch, index) => {
          const isActive = swatch.handle === active_swatch_url;
          const swatchLabel = swatch.label?.split("|")[0];
          return (
            <a
              key={index}
              href={isActive ? undefined : swatch.url}
            >
              {(
                <span
                  className={`product-variants-select__color ${
                    isActive ? 'border border-mb-black' : 'border-transparent'
                  }`}
                  style={{ backgroundColor: swatch.colour }}
                  title={swatch.color}
                />
              )}
            </a>
          );
        })}
      </div>
    );
  };
  

  const { price = 0, compare_at_price_max = 0 } = product;
  const currentPrice = formatPrice(price, currencySymbol);
  const oldPrice = formatPriceWithoutTrailingZeros(
    compare_at_price_max,
    currencySymbol,
  );
  const oldPriceText = interpolateTranslation(
    translations,
    "old_price_html",
    "old_price",
    oldPrice,
  );
  const oldPriceTextSplit = oldPriceText.split(" ");
  const oldPricePricing = oldPriceTextSplit[oldPriceTextSplit.length - 1];
  const oldPriceLabel = oldPriceTextSplit.slice(0, -1).join(" ");
  const newPriceText = interpolateTranslation(
    translations,
    "new_price_html",
    "new_price",
    currentPrice,
  );
  const isSale = compare_at_price_max > 0 && compare_at_price_max > price;
  const { sibling_products: colour_siblings_products } = colour_siblings;
  const badges =
    product.tags &&
    product.tags
      .filter((tag) => tag.includes("tag|"))
      .map((tag) => tag.split("|"));

  let saleEndDate: any;

  const saleEnds = product.tags.find((tag) => tag.includes("sale_ends|"));
  if (saleEnds) {
    const endDateInTag = saleEnds.split("|")[1];

    const now: any = new Date();
    const endDate: any = new Date(endDateInTag);
    const timeDifference = endDate - now;

    saleEndDate = timeDifference > 0 ? endDate : null;
  }

  if (product.media) {
    product.images = product.media.map((media) => media.src);
  }

  if (product.featuredImage) {
    product.featured_image = product.featuredImage.src;
  }

  const [inWishlist, setInWishlist] = useState(false);

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  const checkWishlistList = () => {
    dispatchCustomEvent("check-wishlist", { listType: 'products', itemId: product.id });
  };

  const handleWishlistResponse = (event) => {
    const {detail: { exist, itemId  } } = event;
    if(  itemId == product.id ) {
      setInWishlist(event.detail.exist);
    }
  };
  

  useEffect(() => {
    window.addEventListener("check-wishlist-response", handleWishlistResponse);
    checkWishlistList();
    return () => {
      window.removeEventListener("check-wishlist-response", handleWishlistResponse);
    };
  }, []);

  

  return (
    <div className={`product-tile ${upgradeTile > 0 ? "upgrade" : ""}`}>
      {!hideWishlist && !upgradeTile && (
        <div key={`${product.title}-${inWishlist}`}>
          { inWishlist ? (
          <button
            className="product-tile__wishlist"
            onClick={() => {
              dispatchCustomEvent("remove-wishlist", { listType: 'products', itemId: product.id })
            }
          }
          >
             <FillHeartSVG/>
          </button>
        ) : (
          <button
            className="product-tile__wishlist"
            onClick={() => {
              dispatchCustomEvent("add-wishlist", { listType: 'products', itemId: product.id })
            }}
          >
            <span className="icon-heart text-mb-dark-leaf"></span>
          </button>
        )}
        </div>
      )}
      {upgradeTile > 0 && (
        <a
          href={`/products/${product.handle}`}
          className="product-upgrade-modal__product-title b3 lg:hidden"
        >
          {product.title}
        </a>
      )}
      <div className="product-tile__image-wrapper">
        <a href={`/products/${product.handle}`}>
          <ProductTileImage
            featuredImageUrl={product.featured_image}
            altText={product.title}
            images={product.images}
          />
        </a>

        {!hideTags && badges && !upgradeTile && (
          <ProductTileBadges badges={badges} />
        )}
      </div>

      <div className="product-tile__details">
        {starRatingsApp && !upgradeTile && (
          <ProductTileStarRatingsApp settings={starRatingsApp} />
        )}

        <div className="product-tile__details__title-price">
          <a href={`/products/${product.handle}`}>
            <div className="product-tile__details__title">{product.title}</div>
          </a>

          {saleEndDate && !hideTags ? (
            <div className="product-tile__details__price--sale-ends">
              <div className="product-tile__details__price-compare">
                <span className="product-tile__details__price-value">
                  {currentPrice}
                </span>

                <span className="product-tile__details__price-old montserrat-regular">
                  {oldPriceLabel}{" "}
                  <span className="line-through">
                    {" "}
                    {` ${oldPricePricing}`}{" "}
                  </span>
                </span>

                <span className="product-tile__details__price-value new">
                  {newPriceText}
                </span>
              </div>

              <ProductTileSaleCountdown
                className="product-tile__details__price-end"
                label={translations?.sale_ends || "Sale ends"}
                endDate={saleEndDate}
              />
            </div>
          ) : (
            <div className={`flex flex-col gap-2 lg:gap-0 lg:flex-row  align-center mt-auto w-full ${colour_siblings_products && colour_siblings_products.length > 0 ? 'justify-between' : 'justify-center' } `}> 

              <p className="product-tile__details__price">
                {isSale && !hideCompare ? (
                  <>
                    <span className="product-tile__details__price-old montserrat-regular">
                      {oldPriceLabel}{" "}
                      <span className="line-through">
                        {" "}
                        {` ${oldPricePricing}`}{" "}
                      </span>
                    </span>

                    <span className="product-tile__details__price-value">
                      {newPriceText}
                    </span>
                  </>
                ) : (
                  <span className="product-tile__details__price-value">
                    {currentPrice}
                  </span>
                )}
              </p>

              {colour_siblings_products && colour_siblings_products.length > 0 && (
                <div className="gap-2 flex flex-col  align-center justify-center">
                  {renderSwatches(colour_siblings_products)}
                </div>
              )}

            </div>
        
          )}

 
        </div>
        {!hideAddToCart && (
          <ProductTileAddToCart
            variant={currentVariant}
            addToCartUrl={addToCartUrl}
            translations={translations}
            upgradeTile={upgradeTile}
            chilled={chilled}
            delivery_date={delivery_date}
            deliver_date_end={delivery_date_end}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
}
