import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedCartWrapper from "./ExtendedCartWrapper";
import ExtendedCartAddressInfo from "./ExtendedCartAddressInfo";
import ExtendedCartItems from "./ExtendedCartItems";

import { updateItem, addToCart, fetchCart } from 'frontend/js/useShopifyCart';
import { recipientProperties, constructFullAddress } from 'frontend/js/helpers';


export default function ExtendedCartStep1({
  cart,
  cartItems,
  optionsInCart,
  hasCards,
  customer,
  card_types,
  gift_wrap_types,
  hampers,
  settings,
  translations,
  address,
  multiAddress,
  onClickAddCards,
  onCartItemsChange,
  onAddressChange,
  onMultiAddressChange,
  onStepChange,
  deliveryMap,
  shipping_id,
  bundleType,
  error,
  setError,
  errorMessage,
  setErrorMessage,
  setSelectedShippingType, 
  selectedShippingType,
  shippingTypes
}) {

  const [updatingCart, setUpdatingCart] = useState(false);

  useEffect(() => {
    setUpdatingCart(false);
  }, [cart])

  async function updateLineItems () {

    for (const cartItem of cartItems) {
      let index = 0;
      for (const item of cartItem.items) {
        const shippingProperties: any = {};
        const currAddress = selectedShippingType == 'SINGLE' ? address : multiAddress[item.key];
        console.log(currAddress, 'currAddress');
        for (const key in currAddress) {
          
          const matches = key.match(/(?<=\[)[^\]]+(?=\])/g);
          if (matches?.length) {
            shippingProperties[`_shipping_${matches[0]}`] = currAddress[key];
          }
          
        }
        if(currAddress) {

        shippingProperties._shipping_delivery_instructions = currAddress?.delivery_instructions;
        shippingProperties._shipping_address = constructFullAddress(currAddress);
        if(item.product_type == bundleType) {
          continue;
        }

        const uniqueId = `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        if (item.isNew) {
          await addToCart({
            id: item.variant_id,
            quantity: 1,
            properties: {
              ...item.properties,
              ...shippingProperties,
              ['_address_key']: item.key,
              [`_itemIndex-${uniqueId}`]: `${index}-${item.id}`
            }
          }, false);

        } else {
          await updateItem({
            id: item.key,
            quantity: 1,
            properties: {
              ...item.properties,
              ...shippingProperties,
              ['_address_key']: item.key,
              [`_itemIndex-${uniqueId}`]: `${index}-${item.id}`
            }
          }, false);
        }

        index = index + 1;
        }
      }
    }


    for (const key in optionsInCart) {
      if (optionsInCart[key].isNew && optionsInCart[key].quantity) {
        await addToCart({
          id: optionsInCart[key].id,
          quantity: optionsInCart[key].quantity
        });

      } else if (optionsInCart[key].isUpdated) {
        await updateItem({
          id: optionsInCart[key].key,
          quantity: optionsInCart[key].quantity
        });
      }
    }


  }

  async function handleValidation() {
    setUpdatingCart(true);
    let hasError = false;
    if (selectedShippingType == 'SINGLE') {
      for (const cartItem of cartItems) {
        for (const item of cartItem.items) {
          item.formErrors = {}

          if(item.product_type == bundleType) {
            hasError = false;
            continue;
          }

          if (!item.properties._recipient_name) {
            if(hampers[item.variant_id] && hampers[item.variant_id].length > 0 && item.product_type == 'Hampers') {
              hasError = true;
              const formErrorMessage = 'Please enter a recipient name.'
              item.formErrors.recipient_name = formErrorMessage;
              setErrorMessage(formErrorMessage);
            }
          }
  
          if (hasCards) { 
            if ( item.properties._card_type  && !item.properties._card_message && !item.properties._is_card_message_blank) {
              hasError = true;
              const formErrorMessage = 'Please enter a message.';
              item.formErrors.card_message = formErrorMessage;
              setErrorMessage(formErrorMessage);
            }
  
          } else {
            for (const key in recipientProperties) {
              if (key !== '_recipient_name') {
                delete item.properties[key];
              }
            }
          }
        }
      }

      if (
        address["address[zip]"] == ''
        || address["address[city]"] == ''
        || address["address[address1]"] == ''
        || (settings.is_phone_required && address["address[phone]"] == '')
      ) {
         hasError = true 
         const formErrorMessage = translations.address_error;
         setErrorMessage(formErrorMessage);
      }
    }

    else {
      for (const cartItem of cartItems) {
        for (const item of cartItem.items) {
          item.formErrors = {}
          if(item.product_type == bundleType) {
            hasError = false;
            continue;
          }  


          function isAddressValid(item, addresses){
            const address = addresses[item.key] || addresses[item.properties._address_key];
            const {properties: { _shipping_address1, _shipping_city , _shipping_country, _shipping_first_name, _shipping_last_name, _shipping_phone, _shipping_province,  _shipping_zip} } = item
            if (_shipping_address1 && _shipping_city && _shipping_country && _shipping_first_name && _shipping_last_name && _shipping_phone && _shipping_province && _shipping_zip ) return true;
            if (!address) return false;
        
            // Check required fields are non-empty
            const requiredFields = [
                "address[first_name]",
                "address[last_name]",
                "address[country]",
                "address[phone]",
                "address[address1]",
                "address[city]",
                "address[province]",
                "address[zip]"
            ];
        
            return requiredFields.every(field => address[field] && address[field] !== "");
        }
        
        const isValid = isAddressValid(item, multiAddress);
          if (!isValid) {
            hasError = true 
            const formErrorMessage = 'Please fill address information.';
            setErrorMessage(formErrorMessage);
          }

          if (hasCards) { 
            if ( item.properties._card_type  && !item.properties._card_message && !item.properties._is_card_message_blank) {
              hasError = true;
              const formErrorMessage = 'Please enter a message.';
              item.formErrors.card_message = formErrorMessage;
              setErrorMessage(formErrorMessage);
            }
  
          }
        }
 
        }
      }



    if (hasError) {
      setError(true);
      const cartItemsCopy = cloneDeep(cartItems);
      onCartItemsChange(cartItemsCopy);
      setUpdatingCart(false);

    } else {
      setError(false);
      await updateLineItems();
      await fetchCart();
      setUpdatingCart(false);
      onStepChange(1)
    }
  }

  function handleItemDelete(id, itemIndex) {
    const groupedCartItem = cartItems.find(cartItem => cartItem.variant_id === id)
    if (groupedCartItem) groupedCartItem.items.splice(itemIndex, 1);
  }

  function handleApplyMessageToAll({ lineItem, cardMessage }) {
    lineItem.items.forEach(item => item.properties._card_message = cardMessage);
    const cartItemsCopy = cloneDeep(cartItems);
    onCartItemsChange(cartItemsCopy);
  }



  return (
    <ExtendedCartWrapper
      title={translations.address_info}
      cart={cart}
      settings={settings}
      shipping_id={shipping_id}
      translations={translations}
      activeIndex={0}
      disabled={updatingCart}
      buttonLabel={translations.go_to_shipping_options}
      onNextStep={() => handleValidation()}
    >
      <>
        <ExtendedCartAddressInfo
          customer={customer}
          corporateOrderUrl={settings.corporate_order_url}
          onAddressChange={(formObject) => onAddressChange(formObject)}
          translations={translations}
          settings={settings}
          address={address}
          error={error}
          selectedShippingType={selectedShippingType}
          setSelectedShippingType={setSelectedShippingType}
          shippingTypes={shippingTypes}
          errorMessage={errorMessage}
        />

       {selectedShippingType == 'SINGLE' &&  <hr className='bg-mb-neutral-500 my-6' />}

        <div>
          <div className='lg:flex gap-4 items-center justify-between py-5 pr-2 lg:pr-6 pl-2 mb-8'>
            <h5 className='font-medium max-lg:mb-6'>
              {translations.add_personalised_cards}
            </h5>

            <ExtendedButton
              label={translations.add_cards_gift_wrapping}
              onClick={() => onClickAddCards(true)}
            />
          </div>

          <ExtendedCartItems
            cartItems={cartItems}
            cardTypes={card_types}
            giftWrapTypes={gift_wrap_types}
            optionsInCart={optionsInCart}
            hampers={hampers}
            hasCards={hasCards}
            customer={customer}
            selectedShippingType={selectedShippingType}
            updatingCart={updatingCart}
            characterLimit={settings.character_limit}
            isGiftWrapEnabled={settings.is_gift_wrap_enabled}
            translations={translations}
            bundleType={bundleType}
            multiAddress={multiAddress}
            onMultiAddressChange={(formObject) => onMultiAddressChange(formObject)}
            onItemDelete={(id, itemIndex) => handleItemDelete(id, itemIndex)}
            onApplyMessageToAll={(params) => handleApplyMessageToAll(params)}
          />
        </div>
      </>
    </ExtendedCartWrapper>
  );
};
