const DEV_STORES = {
    'maggiebeer-dev.myshopify.com' : {
        storefront: "9a4ed9aea2e03c45ddd1d8dd2adb3cb7",
        admin: "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"
    },
    "maggie-beer-uat.myshopify.com": {
        storefront: "551ec08269102da3f8566600e7d647b3",
        admin: "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"
    },
    'gifts-australia-prod.myshopify.com': { // Switch back to prod stores when live 
        storefront: "e40f12d9e562b26fe4241ec77ed68eda",
        admin: "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"
    },
    'hamper-emporium-prod.myshopify.com': { 
        storefront: "01ef644e5ed4764d84f0342efa64f373",
        admin: "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"
    }
};

const PROD_STORES = {
   
    'maggie-beer.myshopify.com': {
        storefront: "1a917b57ec15235a7f345e5d2f66609e",
        admin: ""
    }

}

export default {
    isDevStore() {
        return Object.hasOwn(DEV_STORES, Shopify.shop);
    },
    isProdStore() {
        return Object.hasOwn(PROD_STORES, Shopify.shop);
    },
    getPublicAccessToken() {
        if (this.isDevStore()) {
            return DEV_STORES[Shopify.shop].admin;
        } else if (this.isProdStore()) {
            return PROD_STORES[Shopify.shop].admin;
        }
        return null;
    },
    getStorefrontToken() {
        if (this.isDevStore()) {
            return DEV_STORES[Shopify.shop].storefront;
        } else if (this.isProdStore()) {
            return PROD_STORES[Shopify.shop].storefront;
        }
        return null;
    },
    getBrazeToken() {
        return '813e1c8e-282a-4446-baee-d1fc906c81cb'
    }
}
